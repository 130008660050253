import React, {useImperativeHandle, forwardRef, useRef, useState} from 'react'
import {db} from '../../../index'
import './Comments.css'
import {addDoc, collection, serverTimestamp} from 'firebase/firestore'
import ReactS3Client from 'react-aws-s3-typescript'
import {useAuth} from '../../modules/auth'
import {capitalizeName} from '../../helper-functions/CapitalizeName'
import ReadComments from './ReadComments'
import {FileUploader} from 'react-drag-drop-files'
import {postCardComment} from '../../services/comments'
// import SendCommentAlert from '../Select/SendCommentAlert'
import {awsCommentFileUploadConfig} from '../../config/awsCommentFileUpload'
import {TextEditor} from '../TextEditor'
import CustomAlert from '../CustomAlert'
import {checkStringSize} from '../../helper-functions/checkStringSize'
import { useTheme } from '../../context/ThemeContext'
import { KTSVG } from '../../../_metronic/helpers'
import FileIcon from '../GetUploadedFileIcons'
import Skeleton from 'react-loading-skeleton'
import { cleanMentions } from '../../utils/cleanMentions'

const Comments = forwardRef(
  (
    {
      // sendAlertMsgComment,
      optionsForSendAlert,
      sendAlertToComment,
      setSendAlertToComment,
      sendAlertToBoardPerson,
      boardPersonsForSendAlert,
      cardDetails,
      setEditorComment,
      editorComment,
      handleFormSubmission,
      isUserAssignLoading,sendAlertToMentionPerson,commentsLoading,setCommentsLoading
    }: any,
    ref: any
  ) => {
    const {boardId, id: cardId, card_name, board_name} = cardDetails
    const [fileErrorMsgComment, setFileErrorMsgComment] = useState('')
    const [addCommentsLoading, setAddCommentsLoading] = useState(false)
    const {theme} =useTheme()
    // const [uploadedFileURL, setUploadedFileURL] = useState<any>([])
    const [mentions, setMentions] = useState<any>([])
    const [fileInfo, setFileInfo] = useState<any>([])
    const {currentUser} = useAuth()
    const {fullName, id} = currentUser?.data.user
    const s3 = new ReactS3Client({
      ...awsCommentFileUploadConfig,
      dirName: `attachments/${process.env.REACT_APP_ENVIRONMENT}`,
    })

    const childReadCommentsRef: any = useRef()
    // let {
    //     showVideo,
    //     setShowVideo,
    //     video,
    //     setVideo,
    //     isVideoSrcGet,
    //     setIsVideoSrcGet,
    //     imageSrc,
    //     setImageSrc,
    //     show,
    //     setShow,
    //     editor,
    //     setEditor,
    // } = useUImageVideoPreview()
    const postCommentIdHandler = async (commentId: any , files: string[]) => {
      const payload = {
        created_by: fullName,
        firebase_commentId: commentId,
        rawtext: cleanMentions(editorComment.replace(/<[^>]+>/g, '')),
        boardname: board_name?.trim(),
        type: 0,
        boardId,
        cardId,
        card_name,
        files: [
          ...files
       ]
      }

      try {
        await postCardComment(payload, id)
      } catch (error) {
        return error
      } finally {
        setSendAlertToComment([])
        setFileInfo([])
      }
    }
    const sendComment = async () => {
      let files: string[] = []
      setAddCommentsLoading(true)
      const newUploadedFileURLs: string[] = [];
      await Promise.all(fileInfo.map(async (fileInfo: any) => {
        const filename = fileInfo?.name;
        const filenameUpdated = filename?.substring(0, filename.lastIndexOf('.'));
  
        if (filenameUpdated) {
          try {
            const uploadFile = await s3.uploadFile(fileInfo, filenameUpdated);
            newUploadedFileURLs.push(uploadFile.location);
          } catch (error) {
            // console.error('Error uploading file:', error);
          }
        }
      }));
  
  
      // // Update the state with all the uploaded file URLs
      // setUploadedFileURL((prevURLs:any) => [...prevURLs, ...newUploadedFileURLs]);
      files = [...newUploadedFileURLs];

      const payload: any = {
        userId: id,
        name: capitalizeName(fullName),
        createdAt: serverTimestamp(),
        // text: editorComment,
        dateAndTime: new Date().toLocaleString().replace(/\//g, '-'),
        // attachments: [uploadFile?.location],
        id,
      }

      if (checkStringSize(editorComment) > 1048487) {
        setFileErrorMsgComment(
          'The media size exceeds 1MB. please consider file uploader section for large media.'
        )
        setAddCommentsLoading(false)
        return
      } else {
        if (editorComment) payload.text = cleanMentions(editorComment)
        if (mentions.length > 0) payload.alertSentTo = optionsForSendAlert?.filter((option: any) =>
          mentions.some((mention: any) => mention.id === option.value))
        if (newUploadedFileURLs.length > 0) payload.attachments = newUploadedFileURLs;
        if (editorComment.length > 0) {
          try {
            const comment = await addDoc(
              collection(
                db,
                `comments-module/${process.env.REACT_APP_FIREBASE_ENVIRONMENT}/boards/${boardId}/cards/${cardId}/comments`
              ),
              payload
            )
            await postCommentIdHandler(comment.id , files)
            setEditorComment('')
            setFileInfo([])
            sendAlertToMentionPerson(undefined, mentions, 'card-comment',comment?.id)
            setMentions([])
            // if (sendAlertToComment)
            //   sendAlertToBoardPerson(undefined, sendAlertToComment, 'card-comment', null)
          } catch (error) {
            setFileErrorMsgComment(
              'The media size exceeds 1MB. please consider file uploader section for large media.'
            )
          }
        }
      }

      setAddCommentsLoading(false)
    }
    const uploadCommentAttachmentHandler = async (fileList: any) => {
      const files = Array.from(fileList);
      const validFiles = files.filter(
        (file: any) =>
          file.size <= 25000000 &&
          !file.name.toLowerCase().endsWith('.html') &&
          !file.name.toLowerCase().endsWith('.mhtml')
      );
  
      const invalidFiles = files.filter((file: any) => file.size > 25000000);
      const invalidFilesExt = files.filter((file: any) => 
        file.name.toLowerCase().endsWith('.html') || file.name.toLowerCase().endsWith('.mhtml')
      );
      if (invalidFilesExt.length > 0) {
        setFileErrorMsgComment('Does not support this extension');
        
      }
      if (invalidFiles.length > 0) {
        setFileErrorMsgComment('Please attach files less than 25MB!');
      }
      setFileInfo((prevFiles:any) => [...prevFiles, ...validFiles]);
    }

    const addCommentHandler = () => {
      function hasOnlySpaces(str: string) {
        const regex = /<p class="spacer">\s*<\/p>/
        return !regex.test(str)
      }
      const hasSpacesOnly = hasOnlySpaces(editorComment)
      if (hasSpacesOnly && editorComment?.length > 0 && !/<p class="spacer"><br><\/p>/.test(editorComment)) {
        sendComment()
      } else if (fileInfo.length>0) {
        sendComment()
      }
    }

    useImperativeHandle(ref, () => ({
      addComment() {
        addCommentHandler()
      },

      updateComment() {
        childReadCommentsRef.current.childRead()
      },
    }))

    // const handleChange = () => {
    //     var editImg: any = document.querySelectorAll(`div.ql-editor[contenteditable="true"] img`)
    //     for (let i = 0; i < editImg.length; i++) {
    //         editImg[i].style.maxWidth = '30%';
    //         editImg[i].classList.add('m-2');
    //         editImg[i].classList.add('cursor-pointer');
    //         editImg[i].addEventListener('click', () => {
    //             let imageSrc: any = editImg[i].getAttribute('src')
    //             setImageSrc(imageSrc)
    //             setShow(true)
    //         })
    //     }
    // }
    const handleRemoveFile = (indexToRemove:any) => {
      setFileInfo((prevFiles:any) => prevFiles.filter((_:any, index:any) => index !== indexToRemove));
    };


    return (
      <div ref={ref}>
        <div style={{position: 'relative'}}>
          {isUserAssignLoading ? 
          <React.Fragment>
              <Skeleton count={1} height={35} />
              <Skeleton count={1} height={155} />
          </React.Fragment>
          :
          <TextEditor
            boardId={boardId}
            forTitle='comments'
            setEditor={setEditorComment}
            editor={editorComment}
            placeholder={'Write a comment'}
            handleFormSubmission={handleFormSubmission}
            setMentions={setMentions}
            optionsForSendAlert={optionsForSendAlert}
          />
           }
        </div>
        {/* <h6 className={`my-3 ${theme === 'dark' ? 'white__text' : 'text-dark'}`}>Send Alert To:</h6>
        <div className='row'>
          <div className='col-12'>
            <SendCommentAlert
              options={optionsForSendAlert}
              setSelected={setSendAlertToComment}
              selected={sendAlertToComment}
            />
            {sendAlertMsgComment !== '' && (
                        <Alert variant='info' className='mt-2'>
                            {sendAlertMsgComment}
                        </Alert>
                    )}
          </div>
        </div> */}

        <CustomAlert
          variant={'warning'}
          className={'mt-2 w-100'}
          alertMessage={fileErrorMsgComment}
          setAlertMessage={setFileErrorMsgComment}
        />

        <div className='row my-2 d-flex'>
          <div className='col-md-12'>
            <FileUploader
              classes='file-upload'
              handleChange={uploadCommentAttachmentHandler}
              name='file'
              label='Upload or drop attachment here'
              multiple={true}
            />
          </div>
        </div>
        {fileInfo && fileInfo.length>0 && (
          <div className={`col-12 mt-2  my-3 ${theme === 'dark' ? 'theme__div' : 'bg-white'}`}>
            <div className='card-body p-0'>
              <div className='table-responsive' style={{maxHeight: '25vh'}}>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th className='min-w-150px'>File</th>
                      <th className='min-w-100px text-end'>Remove</th>
                    </tr>
                  </thead>

                  <tbody>
                  {fileInfo?.map((file:any,index:any)=>  (
                      <tr key={index}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-45px me-2'>
                              <FileIcon fileName={file?.name} showName={file?.showName} />
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <a
                                href={file?.value}
                                rel='noreferrer'
                                target='_blank'
                                download
                                className={`${
                                  theme === 'dark' ? 'white__text' : ''
                                }  fw-bolder text-hover-primary fs-6`}
                              >
                                {file?.name?.toLowerCase() || file.showName?.toLowerCase()}
                              </a>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <span
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              onClick={() =>  handleRemoveFile(index)}
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-3'
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div></div>
          </div>
        )}
        <div className='d-flex justify-content-end'>
          <button
            type='button'
            className='btn-custom btn-custom-secondary btn-comments'
            onClick={addCommentHandler}
          >
            {addCommentsLoading === true ? 'Adding Comments...' : ' Add Comment'}
          </button>
        </div>

        <div className='mt-5'>
          <ReadComments
            ref={childReadCommentsRef}
            boardPersonsForSendAlert={boardPersonsForSendAlert}
            cardDetails={cardDetails}
            sendComment={sendComment}
            optionsForSendAlert={optionsForSendAlert}
            setSendAlertToComment={setSendAlertToComment}
            sendAlertToComment={sendAlertToComment}
            sendAlertToBoardPerson={sendAlertToBoardPerson}
            handleFormSubmission={handleFormSubmission}
            sendAlertToMentionPerson={sendAlertToMentionPerson}
            commentsLoading={commentsLoading}
            setCommentsLoading={setCommentsLoading}
          />
        </div>
        {/* {imageSrc && <ImageModal imageSrc={imageSrc} setShow={setShow} show={show} />}

            {video.length > 0 && (
                <VideoShowModal videoSrc={video} setShowVideo={setShowVideo} showVideo={showVideo} />
            )} */}
      </div>
    )
  }
)
Comments.displayName = 'Comments'

export default Comments
