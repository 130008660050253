import { findMentionOccurrence, getTextAfterNthMention } from "./findMention";

type Mention = {
    user: string | null;
    id: string | null;
    text: string;
};
export function extractRetainMentions(
    input: string,
    setRetainMentions?: (mentions: Mention[]) => void
): any {
    // Parse the string into a DOM document
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');

    // Select all <p> elements in the parsed document
    const paragraphs: any = doc.querySelectorAll('p');

    let currentUser: string | null = null;
    let currentText = "";
    let currentId: string | null = null;
    let uniquecurrentId: string | null = null;
    const result = [];
    let multipleMentions: any[] = [];
    let dont = false

    // Process each paragraph
    paragraphs.forEach((paragraph: any, paragraphIndex: any) => {
        const isBreak = paragraph.innerHTML === "<br>"; // Detect a single line break
        const mentionSpans = paragraph.querySelectorAll('span.mention[data-denotation-char="@"]'); // Check for mention class 
        mentionSpans?.forEach((mentionSpan: any, index: any) => {
            if (!isBreak) {
                if (mentionSpan) {
                    // If there's an active user, push the accumulated text for that user
                    if (currentUser && !dont) {
                        result.push({ user: currentUser, id: currentId, uniqueId: uniquecurrentId, text: currentText.trim() });
                    }
                    // Update the current user and reset text
                    currentUser = mentionSpan.getAttribute('data-value');
                    currentId = mentionSpan.getAttribute('data-id');
                    uniquecurrentId = mentionSpan.getAttribute('data-unique-id');
                    const occurrenceCount = findMentionOccurrence(mentionSpans, currentUser || '', uniquecurrentId || '');


                    //Check if next span has any text to add in prev user text
                    let nextContent = null;
                    if (mentionSpans[index + 1]) {
                        nextContent = mentionSpans[index + 1].textContent || '';
                    }
                    currentText = getTextAfterNthMention(paragraph.textContent || '', mentionSpan?.textContent || '', occurrenceCount);

                    // currentText = (paragraph.textContent || '').split(mentionSpan?.textContent || '')[1].replace(mentionSpan.textContent || '', '').trim();

                    if (nextContent) {
                        currentText = currentText?.split(nextContent)[0].trim()
                    }


                    let nextParagraphIndex = paragraphIndex + 1;
                    //if it is last span of a paragraph check for text in next paragraph
                    if (index + 1 === mentionSpans.length) {
                        while (
                            nextParagraphIndex < paragraphs.length && paragraphs &&
                            paragraphs[nextParagraphIndex].innerHTML.trim() !== "<br>"
                        ) {
                            // if next para has a mention stop getting text before that
                            const nextLineMentionSpan = paragraphs[nextParagraphIndex].querySelectorAll('span.mention[data-denotation-char="@"]');
                            if (nextLineMentionSpan[0]) {
                                currentText += ` ${paragraphs[nextParagraphIndex]?.textContent.split(nextLineMentionSpan[0]?.innerText)[0].trim()} `
                                break;
                            } else {
                                // else get all the text
                                currentText += ` ${paragraphs[nextParagraphIndex]?.textContent.trim()}`;
                                nextParagraphIndex++;
                            }
                        }
                    }
                    if (mentionSpans[index + 1] && paragraph.textContent
                        .split(mentionSpans[index].textContent)[1] // First split
                        .split(mentionSpans[index + 1]?.textContent || '')[0] // Second split
                        .trim().length <= 0) {
                        dont = true;

                        multipleMentions.push({
                            user: currentUser,
                            id: currentId,
                            uniqueId: uniquecurrentId,
                            text: null
                        });
                        return;
                    }
                    else {
                        dont = false;
                        multipleMentions.forEach(mention => {
                            result.push({
                                user: mention.user,
                                id: mention.id,
                                uniqueId: mention.uniqueId,
                                text: currentText.trim()
                            });
                        });
                        multipleMentions = []
                    }
                }
            }
            else if (isBreak && currentUser) {
                // Push text up to the line break and reset currentUser
                result.push({ user: currentUser, id: currentId, uniqueId: uniquecurrentId, text: currentText.trim() });
                currentUser = null;
                currentId = null;
                uniquecurrentId = null;
                currentText = ""; // Reset text after the line break
            }
            else if (currentUser) {
                // Append text to the current user if no mention or break is found
                currentText += ` ${(paragraph.textContent || '').trim()}`;
            }
        })
    });


    if (currentUser && !dont) {
        result.push({ user: currentUser, id: currentId, uniqueId: uniquecurrentId, text: currentText.trim() });
    }

    if (setRetainMentions) {
        setRetainMentions(result);
    } else {
        return result;
    }
}