import React from 'react'
import {FormikProps} from 'formik'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import useTogglePassword from '../../hooks/useTogglePassword'
import useToggleConfirmPassword from '../../hooks/useToggleConfirmPassword'
import CustomAlert from '../CustomAlert'
import {useTheme} from '../../context/ThemeContext'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

interface Props {
  formik: FormikProps<any>
  handleSubmit: () => void
  title: string
  bodyText: string
  popUpMsg: string
  loading: boolean
  isEmail: boolean
  setPopUpMsg: React.Dispatch<React.SetStateAction<string>>
}
//eslint-disable-next-line
// const passwordReg = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@~\$%\^\*])(?=.{12,})/

const RecoverAccountForm: React.FC<Props> = ({
  formik,
  title,
  bodyText,
  isEmail,
  loading,
  popUpMsg,
  setPopUpMsg,
  handleSubmit,
}) => {
  const {passwordType, togglePassword} = useTogglePassword()
  const {confirmPasswordType, toggleConfirmPassword} = useToggleConfirmPassword()
  const {theme} = useTheme()
  return (
    <form
      className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework ${
        theme === 'dark' ? 'theme__div' : ''
      }`}
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={handleSubmit}
    >
      <div className='text-center mb-10'>
       <div className='d-flex align-items-center justify-content-center gap-5'>
       <h1 className={` mt-7 ${theme === 'dark' ? 'white__text' : 'text-dark'} `}>{title}</h1>
        <a href={process.env.REACT_APP_B4IGO_LANDING_PAGE} className=''>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/b4igo-go.png')}
            className='h-100px w-90px my-5 me-15'
          />
        </a>
       </div>
        <div className='text-gray-400 fw-bold fs-4'>{bodyText}</div>
      </div>
      {isEmail && (
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <div className='input-group'>
            <input
              disabled
              type='email'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                `form-control form-control-lg form-control-solid ${
                  theme === 'dark' ? 'dark__theme__input' : ''
                } `,
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />

            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' className='text-danger'>
                    {formik.errors.email as string}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className='fv-row mb-10'>
        <div className='fv-row mb-10'>
          <label className={`form-label fw-bolder  fs-6  ${theme === 'dark' ? 'white__text' : 'text-gray-900'}`}>New Password</label>
          <div className='input-group'>
            <input
              type={passwordType}
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                `form-control form-control-lg form-control-solid ${
                  theme === 'dark' ? 'dark__theme__input' : ''
                } `,
                {'is-invalid': formik.touched.password && formik.errors.password},
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            <div className='input-group-btn'>
              <button
               
                className={`${
                  theme === 'dark' ? 'theme__div' : ''
                } btn border-end border-top border-bottom`}
                style={{borderRadius: '0px 10px 10px 0px'}}
                type='button'
                onClick={togglePassword}
              >
                {passwordType === 'password' ? (
                  <i className={`bi bi-eye-slash ${theme === 'dark' ? 'white__text' : ''}`}></i>
                ) : (
                  <i className={`bi bi-eye ${theme === 'dark' ? 'white__text' : ''}`}></i>
                )}
              </button>
            </div>
          </div>

          {formik.touched.password && formik.errors.password ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='text-danger' style={{color: 'red'}}>
                  {formik.errors.password as string}
                </span>
              </div>
            </div>
          ) : (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert' className='' style={{color: 'gray'}}>
                  Please use 12 or more characters with a mix of letters, numbers and any of the
                  following special characters: !,@,~,$,%,^ or *
                </span>
              </div>
            </div>
          )}
          {/* ============================================================= */}
        </div>
      </div>
      <div className='fv-row mb-10'>
        <label className={`form-label fw-bolder  fs-6  ${theme === 'dark' ? 'white__text' : 'text-gray-900'}`}>Confirm Password</label>
        <div className='input-group'>
          <input
            type={confirmPasswordType}
            autoComplete='off'
            {...formik.getFieldProps('confirmPassword')}
            className={clsx(
              `form-control form-control-lg form-control-solid ${
                theme === 'dark' ? 'dark__theme__input' : ''
              } `,
              {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
              {
                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
              }
            )}
          />
          <div className='input-group-btn'>
            <button
              className={`${
                        theme === 'dark' ? 'theme__div' : ''
                      } btn border-end border-top border-bottom`}
              style={{borderRadius: '0px 10px 10px 0px'}}
              type='button'
              onClick={toggleConfirmPassword}
            >
              {confirmPasswordType === 'password' ? (
                <i className={`bi bi-eye-slash ${theme === 'dark' ? 'white__text' : ''}`}></i>
              ) : (
                <i className={`bi bi-eye ${theme === 'dark' ? 'white__text' : ''}`}></i>
              )}
            </button>
          </div>
        </div>
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='text-danger' style={{color: 'red'}}>
                {formik.errors.confirmPassword as string}
              </span>
            </div>
          </div>
        )}
        {/* {passwordState === confirmPasswordState ? null : (
                    <div
                        className='error'
                        style={{
                            color: 'red',
                            fontSize: '1rem',
                        }}
                    >
                        Passwords must be same.
                    </div>
                )} */}

        {/* =================================================== */}
      </div>

      <div className='my-3'>
        <CustomAlert
          variant={'info'}
          alertMessage={popUpMsg}
          setAlertMessage={setPopUpMsg}
          time={4000}
        />
      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-lg btn-primary fw-bolder me-4'
          disabled={!formik.isValid || loading}
        >
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-lg btn-secondary fw-bolder'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
    </form>
  )
}

export default RecoverAccountForm
