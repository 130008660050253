import clsx from 'clsx'
import React from 'react'
import {Link} from 'react-router-dom'
import {FormikProps} from 'formik'
import CustomAlert from '../CustomAlert'
import {useTheme} from '../../context/ThemeContext'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

interface MyFormValues {
  email: string
}

interface MyComponentProps {
  formik: FormikProps<MyFormValues>
  handleSubmit: () => void
  title: string
  popUpMsg: string
  loading: boolean
  setPopUpMsg: React.Dispatch<React.SetStateAction<string>>
}

const VerifyEmailForm: React.FC<MyComponentProps> = ({
  handleSubmit,
  title,
  popUpMsg,
  setPopUpMsg,
  formik,
  loading,
}) => {
  const {theme} = useTheme()
  return (
    <form
      className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework  ${
        theme === 'dark' ? 'theme__div' : ''
      }`}
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={handleSubmit}
    >
      <div className='text-center mb-10'>
        <div className='d-flex align-items-center justify-content-center gap-5'>
        <h1 className={`${theme === 'dark' ? 'white__text' : 'text-dark'}  mt-7`}>{title}</h1>
        <a href={process.env.REACT_APP_B4IGO_LANDING_PAGE} className=''>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/b4igo-go.png')}
            className='h-100px w-90px my-5 me-15'
          />
        </a>
        </div>
        <div className='text-gray-400 fw-bold fs-4'>Enter your email.</div>
      </div>

      <div className='fv-row mb-10'>
        <label
          className={`${
            theme === 'dark' ? 'white__text' : 'text-gray-900'
          } form-label fw-bolder fs-6`}
        >
          Email
        </label>
        <input
          type='email'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            `form-control form-control-lg form-control-solid  ${theme === 'dark' ? 'dark__theme__input' : ''}`,
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='text-danger' style={{color: 'red'}}>
                {formik.errors.email}
              </span>
            </div>
          </div>
        )}
      </div>

      <div className='my-3'>
        <CustomAlert variant={'info'} alertMessage={popUpMsg} setAlertMessage={setPopUpMsg} />
      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-lg btn-primary fw-bolder me-4'
          disabled={loading}
        >
          <span className='indicator-label'>Submit</span>
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-lg btn-secondary fw-bolder'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
    </form>
  )
}

export default VerifyEmailForm
