import React, {useEffect, useState} from 'react'
import {TextEditor} from '../../../../components/TextEditor'
import {useFormik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useAuth} from '../../../auth'
import {isValidPhoneNumber} from 'libphonenumber-js'
import {useManageContacts} from '../../../../hooks'
import {useParams} from 'react-router-dom'
import {sendInvitation} from '../../../../services/invitation'
import PhoneInput from 'react-phone-input-2'

import {useDispatch} from 'react-redux'
import {setContactDetailSlice} from '../../../../store/slice/contact-detail-slice'

import 'react-phone-input-2/lib/style.css'
import {useListView} from '../core/ListViewProvider'
import CustomAlert from '../../../../components/CustomAlert'
import {InviteContactsProps} from '../../../../models/Contacts'
import {useTheme} from '../../../../context/ThemeContext'
import { useSelector } from 'react-redux'

enum RoleOption {
  Admin = 'Admin',
  Member = 'Member',
  Guest = 'Guest',
}
//eslint-disable-next-line
const cityRgx = /^[A-Za-z\s]+$/
const AddNewContactSchema = Yup.object().shape({
  fullName: Yup.string()
    .max(40, 'Maximum 100 characters are allowed.')
    .required('Name is required')
    .trim(),
  // .matches(nameReg, 'Name should be in Upper or Lower case letters'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  contact: Yup.string().nullable(true),
  businessNumber: Yup.string().nullable(true),
  website: Yup.string().nullable(true),
  designation: Yup.string().nullable(true),
  role: Yup.mixed<RoleOption>()
    .oneOf(Object.values(RoleOption) as any)
    .required('Select Role'),
  streetAddress: Yup.string().nullable(true),
  city: Yup.string().nullable(true).matches(cityRgx, 'Please enter city name with alphabets only.'),
  zipCode: Yup.string().nullable(true),
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const InviteContacts: React.FC<InviteContactsProps> = ({
  getMembersHandler,
  buttonTitle,
  handleClose,
  inviteFlag,
  getOtherContactHandler,
  setFormValues,
  formValues,
}: any) => {
  const {theme} = useTheme()
  const {currentUser} = useAuth()
  const {id, fullName, email: userEmail} = currentUser?.data.user
  const {allRoles:roleList}: any = useSelector((state: any) => state.PersonRoleReducer)
  const params = useParams()
  const {setItemIdForUpdate} = useListView()
  const [phoneState, setPhoneState] = useState<any>(formValues.contact || null)
  const [businessNumberState, setBusinessNumberState] = useState<any>(
    formValues.businessNumber || null
  )

  const dispatch = useDispatch()
  const {allBoards} = useSelector((state: any) => state.allBoardsReducer)

  const [editor, setEditor] = useState(formValues.notes || null)

  const [loading, setLoading] = useState(false)
  const [isContactValid, setIsContactValid] = useState(true)
  const [errorMsgRegistration, setErrorMsgRegistration] = useState('')
  const [isBusinessNumberValid, setIsBusinessValid] = useState(true)
  const {createOtherContactHandler, isInvited, setIsInvited} = useManageContacts()
  const [sendAgainInviteError, setSendAgainInviteError] = useState('')

  const getBoardFromAllBoards = (allBoards: any) => {
    return allBoards?.find((item: any) => item.board.id === params.boardId)
  }
  let board: any
  if (inviteFlag) {
    // const {allBoards} = useGetAllBoards()
    board = getBoardFromAllBoards(allBoards)
  }

  const formik = useFormik({
    initialValues: {
      ...formValues,
      role: 'Admin',
    },
    validationSchema: AddNewContactSchema,
    // enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        setLoading(true)

        let validContact: any
        let validBusinessNumber: any
        if (phoneState && phoneState.length !== '') {
          validContact = isValidPhoneNumber('+' + phoneState)
        } else {
          validContact = false
        }
        if (businessNumberState && businessNumberState.length !== '') {
          validBusinessNumber = isValidPhoneNumber('+' + businessNumberState)
        } else {
          validBusinessNumber = false
        }
        const modifiedValues = {...values}
        // modifiedValues.organizationId=currentAccount.accountId
        modifiedValues.userId = id
        // modifiedValues.companyName = currentAccount.name

        if (phoneState ? phoneState && phoneState.length > 0 && validContact : true) {
          setIsContactValid(true)
          if (businessNumberState ? businessNumberState.length > 0 && validBusinessNumber : true) {
            setIsBusinessValid(true)
            if (editor && editor.length > 0) {
              modifiedValues.notes = editor
            }
            if (validContact) modifiedValues.contact = phoneState
            if (validBusinessNumber) modifiedValues.businessNumber = businessNumberState
            if (modifiedValues.website === '') modifiedValues.website = null
            if (modifiedValues.streetAddress === '') modifiedValues.streetAddress = null
            if (modifiedValues.city === '') modifiedValues.city = null
            if (modifiedValues.zipCode === '') modifiedValues.zipCode = null

            try {
              const {role, ...restData} = modifiedValues
              const createResult: any = await createOtherContactHandler(restData)

              const roleValue = role ? role : values.role
              const roleId = roleList.find((roles: any) => roles.name === roleValue)?.id

              if (createResult && inviteFlag) {
                const inviteeArray = [
                  {
                    email: values.email,
                    boardId: board?.board.id,
                    roleId: roleId,
                    inviteBy: fullName,
                    inviteByUserId: id,
                    boardName: board?.board.name.trim(),
                    inviterEmail: userEmail,
                  },
                ]

                const payload = {inviteeArray}
                const responseSend = await sendInvitation(payload)

                if (responseSend?.data?.data[values.email].message) {
                  setSendAgainInviteError(responseSend?.data?.data[values.email].message)
                }
              }
              setTimeout(() => {
                if (createResult) {
                  setFormValues({})
                  handleClose()
                }
                if (!inviteFlag) {
                  getOtherContactHandler()
                } else {
                  setItemIdForUpdate(undefined)
                  getMembersHandler()
                }
              }, 4000)
            } catch (error: any) {
              const msg = error.response.data.message
              setErrorMsgRegistration(msg)
            } finally {
              setTimeout(() => {
                formik.resetForm()
                dispatch(setContactDetailSlice({currentDetail: {}}))
              }, 3000)
            }
          } else {
            setIsBusinessValid(false)
          }
        } else {
          setIsContactValid(false)
        }
        setTimeout(() => {
          setSubmitting(false)
        }, 5000)
      } catch (error: any) {
        return error
      } finally {
        setLoading(false)
      }
    },
  })
  // useEffect(() => {
  //   getRolesHandler()
  //   //eslint-disable-next-line
  // }, [])

  useEffect(() => {
    setFormValues(formik.values)
    //eslint-disable-next-line
  }, [formik.values])
  useEffect(() => {
    setFormValues({...formValues, businessNumber: businessNumberState})
    //eslint-disable-next-line
  }, [businessNumberState])
  useEffect(() => {
    setFormValues({...formValues, contact: phoneState})
    //eslint-disable-next-line
  }, [phoneState])
  useEffect(() => {
    setFormValues({...formValues, notes: editor})
    //eslint-disable-next-line
  }, [editor])

  return (
    <div>
      <form
        className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework   ${
          theme === 'dark' ? 'theme__div' : ''
        } `}
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='w-100 min-h-350px '>
          {inviteFlag && (
            <div className='col-6 mx-3 my-2'>
              <div className={`fs-5 fw-semibold mt-4 ${theme === 'light' ? 'dark__text' : ''}`}>Role</div>

              <div className='mt-5 d-flex align-items-center justify-content-start gap-12'>
                <label className='form-check-label'>
                  <input
                    type='radio'
                    {...formik.getFieldProps('role')}
                    value='Admin'
                    className='form-check-input mr-2'
                    checked={formik.values.role === 'Admin'}
                  />
                  <span className='text-gray-600 fw-bold' style={{marginLeft: '5px'}}>
                    Admin
                  </span>
                </label>
                <label className='form-check-label'>
                  <input
                    type='radio'
                    {...formik.getFieldProps('role')}
                    value='Member'
                    className='form-check-input mr-2'
                    checked={formik.values.role === 'Member'}
                  />
                  <span className='text-gray-600 fw-bold' style={{marginLeft: '5px'}}>
                    Member
                  </span>
                </label>

                <label className='form-check-label'>
                  <input
                    type='radio'
                    {...formik.getFieldProps('role')}
                    value='Guest'
                    className='form-check-input mr-2'
                    checked={formik.values.role === 'Guest'}
                  />
                  <span className='text-gray-600 fw-bold' style={{marginLeft: '5px'}}>
                    Guest
                  </span>
                </label>
              </div>

              {formik.touched.role && formik.errors.role && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.role as string}
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className='row w-100 m-auto'>
            {/* <div className='col-4 w-25 m-auto'> */}
            <div className='col-lg-6 col-md-6 col-12 '>
               <div className={`fs-5 fw-semibold mt-3 ${theme === 'light' ? 'dark__text' : ''}`}>
                {' '}
                Name<span className='text-danger'>*</span>
              </div>
              {/* </div> */}
              <input
                placeholder='Enter Name'
                autoComplete='off'
                type='text'
                {...formik.getFieldProps('fullName')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder mt-2`,
                  {
                    'is-invalid': formik.touched.fullName && formik.errors.fullName,
                  },
                  {
                    'is-valid': formik.touched.fullName && !formik.errors.fullName,
                  }
                )}
              />
              {formik.touched.fullName && formik.errors.fullName && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.fullName as any}
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-lg-6 col-md-6 col-12 '>
              <div className={`fs-5 fw-semibold mt-3 ${theme === 'light' ? 'dark__text' : ''}`}>
                Email Address<span className='text-danger'>*</span>
              </div>
              {/* </div> */}
              <input
                placeholder='Enter Email Address'
                type='email'
                autoComplete='off'
                {...formik.getFieldProps('email')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder mt-2`,
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.email as any}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row w-100 m-auto mt-5'>
            {/* <div className='col-4 w-25 m-auto'> */}
            <div className='col-lg-6 col-md-6 col-12 '>
               <div className={`fs-5 fw-semibold mt-3 ${theme === 'light' ? 'dark__text' : ''}`}>Contact Number</div>

              <div className='mt-2'>
                <PhoneInput
                  placeholder='+44 7700 900077'
                  // inputStyle={{background:'#f5f8fa',height:'45px', border:0}}
                  inputClass={`w-100 ${theme === 'dark' ? 'white__text' : ''}`}
                  dropdownClass={theme === 'dark' ? 'theme__div' : ''}
                  containerClass={
                    theme === 'dark' ? 'react-tel-input-dark' : 'react-tel-input-light'
                  }
                  country={'44'}
                  value={phoneState}
                  onChange={(phone) => setPhoneState(phone)}
                />
              </div>

              {!isContactValid && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {'Please Enter Valid Contact Number!'}
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-lg-6 col-md-6 col-12 '>
               <div className={`fs-5 fw-semibold mt-3 ${theme === 'light' ? 'dark__text' : ''}`}>Business Number</div>
              {/* </div> */}
              <div className='mt-2'>
                <PhoneInput
                  placeholder='+44 77 0090 0077'
                  // inputStyle={{background:'#f5f8fa',height:'45px', border:0}}
                  inputClass={`w-100 ${theme === 'dark' ? 'white__text' : ''}`}
                  dropdownClass={theme === 'dark' ? 'theme__div' : ''}
                  containerClass={
                    theme === 'dark' ? 'react-tel-input-dark' : 'react-tel-input-light'
                  }
                  // country={'44'}
                  masks={{ gb: '.. .... ....' }}
                  value={businessNumberState}
                  onChange={(phone) => setBusinessNumberState(phone)}
                />
              </div>

              {!isBusinessNumberValid && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {'Please Enter Valid Business Number!'}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row w-100 m-auto mt-2'>
            {/* <div className='col-4 w-25 m-auto'> */}
            <div className='col-lg-6 col-md-6 col-12'>
               <div className={`fs-5 fw-semibold mt-3 ${theme === 'light' ? 'dark__text' : ''}`}>City</div>
              {/* </div> */}
              <input
                placeholder='Enter City'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('city')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder mt-2`,
                  {'is-invalid': formik.touched.city && formik.errors.city},
                  {
                    'is-valid': formik.touched.city && !formik.errors.city,
                  }
                )}
              />
              {formik.touched.city && formik.errors.city && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.city as any}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* </div> */}
            {/* <div className='row w-100 m-auto'> */}
            {/* <div className='col-4 w-25 m-auto'> */}
            <div className='col-lg-6 col-md-6 col-12'>
               <div className={`fs-5 fw-semibold mt-3 ${theme === 'light' ? 'dark__text' : ''}`}>Post/Zip Code</div>
              {/* </div> */}
              <input
                maxLength={10}
                placeholder='Enter Zip Code'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('zipCode')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder mt-2`,
                  {'is-invalid': formik.touched.zipCode && formik.errors.zipCode},
                  {
                    'is-valid': formik.touched.zipCode && !formik.errors.zipCode,
                  }
                )}
              />
              {formik.touched.zipCode && formik.errors.zipCode && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.zipCode as any}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row w-100 m-auto mt-5'>
            {/* <div className='col-4 w-25 m-auto'> */}
            <div className='col-lg-6 col-md-6 col-12'>
               <div className={`fs-5 fw-semibold mt-3 ${theme === 'light' ? 'dark__text' : ''}`}>Website </div>
              {/* </div> */}
              <input
                placeholder='Enter Website Link'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('website')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder mt-2`,
                  {'is-invalid': formik.touched.website && formik.errors.website},
                  {
                    'is-valid': formik.touched.website && !formik.errors.website,
                  }
                )}
              />
              {formik.touched.website && formik.errors.website && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.website as any}
                    </span>
                  </div>
                </div>
              )}
            </div>

            {/* </div> */}
            {/* <div className='row w-100 m-auto'> */}
            {/* <div className='col-4 w-25 m-auto'> */}
            {/* </div> */}
            <div className='col-lg-6 col-md-6 col-12'>
               <div className={`fs-5 fw-semibold mt-3 ${theme === 'light' ? 'dark__text' : ''}`}>Street Address </div>
              <input
                placeholder='Enter Street Address'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('streetAddress')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder mt-2`,
                  {'is-invalid': formik.touched.streetAddress && formik.errors.streetAddress},
                  {
                    'is-valid': formik.touched.streetAddress && !formik.errors.streetAddress,
                  }
                )}
              />
              {formik.touched.streetAddress && formik.errors.streetAddress && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.streetAddress as any}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row w-100 m-auto mt-5'>
            <div className='col-lg-6 col-md-6 col-12'>
               <div className={`fs-5 fw-semibold mt-3 ${theme === 'light' ? 'dark__text' : ''}`}>Company Name</div>
              {/* </div> */}
              <input
                placeholder='Enter Company Name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('companyName')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder mt-2`,
                  {'is-invalid': formik.touched.companyName && formik.errors.companyName},
                  {
                    'is-valid': formik.touched.companyName && !formik.errors.companyName,
                  }
                )}
              />
              {formik.touched.companyName && formik.errors.companyName && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.companyName as any}
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-lg-6 col-md-6 col-12'>
               <div className={`fs-5 fw-semibold mt-3 ${theme === 'light' ? 'dark__text' : ''}`}>Role</div>
              {/* </div> */}
              <input
                placeholder='Enter Contact Role'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('designation')}
                className={clsx(
                  `form-control form-control-lg form-control-solid  ${
                    theme === 'dark' ? 'dark__theme__input' : ''
                  }  italics-placeholder mt-2`,
                  {'is-invalid': formik.touched.designation && formik.errors.designation},
                  {
                    'is-valid': formik.touched.designation && !formik.errors.designation,
                  }
                )}
              />
              {formik.touched.designation && formik.errors.designation && (
                <div className='fv-plugins-message-container mt-1'>
                  <div className='fv-help-block'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.designation as any}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={`fs-4 fw-semibold my-4 text-center ${theme === 'light' ? 'dark__text' : ''}`}>Notes</div>
          <TextEditor
            placeholder={'Enter notes'}
            setEditor={setEditor}
            editor={editor}
            boardId={params.boardId}
          />

          <div className='my-3'>
            <CustomAlert
              variant={'danger'}
              alertMessage={errorMsgRegistration}
              setAlertMessage={setErrorMsgRegistration}
              time={5000}
            />
          </div>

          {sendAgainInviteError && sendAgainInviteError?.length > 0 && (
            <div className='my-3'>
              <CustomAlert
                variant={'info'}
                alertMessage={sendAgainInviteError}
                setAlertMessage={setSendAgainInviteError}
                time={5000}
              />
            </div>
          )}

          <div className='my-3'>
            <CustomAlert
              variant={'info'}
              alertMessage={isInvited}
              setAlertMessage={setIsInvited}
              time={5000}
            />
          </div>
        </div>
        <div className='d-flex flex-column-reverse flex-sm-row justify-content-sm-center justify-content-lg-end justify-content-md-end gap-2 w-100'>
          <div className='d-flex justify-content-center justify-content-lg-end justify-content-md-end pt-4'>
            <button
              type='button'
              className='btn btn-secondary'
              data-kt-users-modal-action='submit'
              onClick={() => {
                formik.resetForm()
                handleClose()
                setFormValues({})
                dispatch(setContactDetailSlice({currentDetail: {}}))
              }}
            >
              Cancel
            </button>
          </div>
          <div className=' d-flex justify-content-center justify-content-lg-end justify-content-md-end pt-4'>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-users-modal-action='submit'
              disabled={
                loading ||
                formik.isSubmitting ||
                !formik.isValid ||
                !formik.values.role ||
                !formik.values.fullName
              }
            >
              {!loading && <span className='indicator-label'>{buttonTitle}</span>}
              {loading && <span className='indicator-label'>Loading...</span>}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default InviteContacts
