import React, { useState } from 'react'
import { getBoardPersonById } from '../services/boards'
import { useAuth } from '../modules/auth'
import { capitalizeEachWord } from '../helper-functions/CapitalizeName'

const useBoardPersons = (cardDetails: any = undefined): {
  boardPerson: { id: string, name: string, email: string, role: string } | undefined;
  setBoardPerson: React.Dispatch<React.SetStateAction<{ id: string, name: string, email: string, role: string } | undefined>>;
  optionsOfAssignee: { label: string, value: string, isDisabled: boolean }[];
  selectAutoAssignee: { label: string, value: string, isDisabled: boolean }[];
  selectAutoAssigneeForNotes: { label: string, value: string, isDisabled: boolean, isOwner: boolean }[];
  optionsForSendAlert: { label: string, value: string, isDisabled: boolean }[];
  optionsForSendAlertQuickNote: { label: string, value: string, isDisabled: boolean }[];
  boardPersonsForSendAlert: { email: string, id: string, isDeleted: boolean }[];
  getBoardPersonHandler: (boardID: string, page?: any) => Promise<unknown>;
  isUserAssignLoading: boolean
  setSelectAutoAssignee: React.Dispatch<React.SetStateAction<{ label: string, value: string, isDisabled: boolean }[]>>;
  setSelectAutoAssigneeForNotes: React.Dispatch<React.SetStateAction<{ label: string, value: string, isDisabled: boolean, isOwner: boolean }[]>>;
} => {
  const [boardPerson, setBoardPerson] = useState<any>()
  const [isUserAssignLoading, setIsUserAssignLoading] = useState<boolean>(false)
  const [optionsOfAssignee, setOptionsOfAssignee] = useState<any>([])
  const [selectAutoAssignee, setSelectAutoAssignee] = useState<any>([])
  const [selectAutoAssigneeForNotes, setSelectAutoAssigneeForNotes] = useState<any>([])
  const [optionsForSendAlert, setOptionsForSendAlert] = useState<any>([])
  const [optionsForSendAlertQuickNote, setOptionsForSendAlertQuickNote] = useState<any>([])
  const [boardPersonsForSendAlert, setBoardPersonsForSendAlert] = useState<any>([])
  const { currentUser } = useAuth()
  const { fullName, email } = currentUser?.data.user

  const findBoardPerson = (allPersons: any) => {
    const sanitizePersonData = allPersons?.map((item: any) => {
      return {
        id: item.userId,
        name: item.user.fullName,
        email: item.user.email,
        role: item.role.name,
      }
    })
    const boardPersonObj = sanitizePersonData?.find((persons: any) => persons.email === email)
    setBoardPerson(boardPersonObj)
  }

  const sanitizeAssignees = (allPersons: any) => {
    const optionsForAssignee = allPersons?.map((item: any) => {
      return {
        label:
          capitalizeEachWord(item.user.fullName),
        value: item.id,
        isDisabled: item.isDeleted,
        isOwner: item.isOwner,
      }
    })
    setOptionsOfAssignee(optionsForAssignee)


    if (cardDetails) {
      let defaultAssignees: any
      defaultAssignees = cardDetails?.assignees?.map((name: string) => {
        return capitalizeEachWord(name)
      })
      // for notes boardPersons 
      if (cardDetails.title) {

        defaultAssignees = cardDetails?.board_people?.map((name: any) => {
          return capitalizeEachWord(name?.user.fullName)
        })
      }
      const uniqueAssignes = [...new Set(defaultAssignees || [])]
      const autoAssignee: any = []

      if (cardDetails?.title) {
        for (let i = 0; i < optionsForAssignee.length; i++) {
          for (let j = 0; j < uniqueAssignes?.length; j++) {
            if (optionsForAssignee[i].label === uniqueAssignes[j] || optionsForAssignee[i].isOwner) {
              autoAssignee.push(optionsForAssignee[i])
            }
          }
        }
        setSelectAutoAssigneeForNotes(autoAssignee)
      } else {
        for (let i = 0; i < optionsForAssignee.length; i++) {
          for (let j = 0; j < uniqueAssignes?.length; j++) {
            if (optionsForAssignee[i].label === uniqueAssignes[j]) {
              autoAssignee.push(optionsForAssignee[i])
            }
          }
        }
        setSelectAutoAssignee(autoAssignee)
      }
    } else {
      const autoAssigneeAddCard: any = optionsForAssignee.find(
        (item: any) => item.label === capitalizeEachWord(fullName)
      )
      const autoAssigneeAddCardForNotes: any = optionsForAssignee.filter(
        (item: any) => item.label === capitalizeEachWord(fullName) || item.isOwner
      )
      setSelectAutoAssignee([autoAssigneeAddCard])
      setSelectAutoAssigneeForNotes(autoAssigneeAddCardForNotes)
    }
  }

  const sanitizeSendAlertPersons = (persons: any) => {
    const optionsForSendSAlert = []

    for (let index = 0; index < persons.length; index++) {
      if (persons[index].user_email !== email) {
        optionsForSendSAlert.push({
          label:
            capitalizeEachWord(persons[index].user.fullName),
          value: persons[index].userId,
          isDisabled: persons[index].isDeleted,
        })
      }
    }

    setOptionsForSendAlert(optionsForSendSAlert)

    const boardPersonsForSendAlert = persons?.map((item: any) => {
      return { email: item.user.email, id: item.userId, isDeleted: item.isDeleted,name:item.user.fullName }
    })

    setBoardPersonsForSendAlert(boardPersonsForSendAlert)
  }
  const sanitizeSendAlertPersonsQuickNote = (persons: any, page: any) => {

    const optionsForSendSAlertQuickNote = []
    const boardPeopleIds = new Set(page.board_people.map((pagePerson: any) => pagePerson.id));

    const filteredPersons = persons.filter((person: any) => boardPeopleIds.has(person.id));



    for (let index = 0; index < filteredPersons.length; index++) {
      if (filteredPersons[index].user_email !== email) {
        optionsForSendSAlertQuickNote.push({
          label:
            capitalizeEachWord(filteredPersons[index].user.fullName),
          value: filteredPersons[index].userId,
          isDisabled: filteredPersons[index].isDeleted,
        })
      }
    }

    setOptionsForSendAlertQuickNote(optionsForSendSAlertQuickNote)

    // const boardPersonsForSendAlert = persons?.map((item: any) => {
    //   return {email: item.user.email, id: item.userId, isDeleted: item.isDeleted}
    // })

    // setBoardPersonsForSendAlert(boardPersonsForSendAlert)
  }

  const getBoardPersonHandler = async (boardID: string, page: any = undefined) => {
    try {
      setIsUserAssignLoading(true)
      const res = await getBoardPersonById(boardID)
      const allPersons = res.data.data
      findBoardPerson(allPersons)
      sanitizeAssignees(allPersons)
      sanitizeSendAlertPersons(allPersons)
      if (page) {
        sanitizeSendAlertPersonsQuickNote(allPersons, page)
      }
    } catch (error) {
      return error
    } finally {
      setIsUserAssignLoading(false)
    }
  }

  return {
    boardPerson,
    setBoardPerson,
    optionsOfAssignee,
    selectAutoAssignee,
    selectAutoAssigneeForNotes,
    setSelectAutoAssignee,
    setSelectAutoAssigneeForNotes,
    optionsForSendAlert,
    boardPersonsForSendAlert,
    isUserAssignLoading,
    optionsForSendAlertQuickNote,
    getBoardPersonHandler,

  }
}

export default useBoardPersons
