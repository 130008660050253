import React from 'react'
import {Link} from 'react-router-dom'
import {FormikProps} from 'formik'
import clsx from 'clsx'
import useTogglePassword from '../../hooks/useTogglePassword'
import CustomAlert from '../CustomAlert'
import {useTheme} from '../../context/ThemeContext'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

interface Props {
  formik: FormikProps<any>
  handleSubmit: () => void
  errorMsg: string
  accountLockMsg: string
  loading: boolean
  setErrorMsg: React.Dispatch<React.SetStateAction<string>>
  setAccountLockMsg: React.Dispatch<React.SetStateAction<string>>
}

const LoginForm: React.FC<Props> = ({
  formik,
  handleSubmit,
  errorMsg,
  setErrorMsg,
  loading,
  setAccountLockMsg,
  accountLockMsg,
}) => {
  const {passwordType, togglePassword} = useTogglePassword()
  const {theme} = useTheme()
  return (
    <form className='form w-100' onSubmit={handleSubmit} noValidate id='kt_login_signin_form'>
      <div className='text-center mb-10'>
       <div  className='d-flex align-items-center justify-content-center gap-5'>
       <h1 className='text-dark mt-7'>
          <span className={theme === 'dark' ? 'white__text' : ''}> Sign in to</span>{' '}<span className={`fw-semibold ${theme === 'dark' ? 'white__text' : ''}`}>B4i</span>
          <span className='text-primary fw-semibold'>GO</span>
        </h1>
        <a href={process.env.REACT_APP_B4IGO_LANDING_PAGE} className=''>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/b4igo-go.png')}
            className='h-100px w-90px my-5 me-15'
          />
        </a>
       </div>
      </div>

      <div className='fv-row mb-10'>
        <label
          className={`form-label fs-6 fw-bolder ${theme === 'dark' ? 'white__text' : 'text-dark'}`}
        >
          Email
        </label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            `form-control form-control-lg form-control-solid  ${
              theme === 'dark' ? 'dark__theme__input' : ''
            }`,
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert' className='text-danger'>
              {formik.errors.email as any}
            </span>
          </div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label
              className={`form-label fw-bolder  fs-6 mb-0 ${
                theme === 'dark' ? 'white__text' : 'text-dark'
              }`}
            >
              Password
            </label>
          </div>
        </div>
        <div className='input-group'>
          <input
            type={passwordType}
            placeholder='Enter Your Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              `form-control form-control-lg form-control-solid  ${
                theme === 'dark' ? 'dark__theme__input' : ''
              }`,
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <div className='input-group-btn'>
            <button
              className={` ${
                theme === 'dark' ? 'layout__theme__div' : ''
              }  btn border-end border-top border-bottom`}
              style={{borderRadius: '0px 10px 10px 0px'}}
              type='button'
              onClick={togglePassword}
            >
              {passwordType === 'password' ? (
                <i className={`bi bi-eye-slash ${theme === 'dark' ? 'white__text' : ''}`}></i>
              ) : (
                <i className={`bi bi-eye ${theme === 'dark' ? 'white__text' : 'text-dark'}`}></i>
              )}
            </button>
          </div>
        </div>

        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert' className='text-danger' style={{color: 'red'}}>
                {formik.errors.password as any}
              </span>
            </div>
          </div>
        )}

        <div className='mt-4'>
          <CustomAlert
            variant={'danger'}
            alertMessage={errorMsg}
            setAlertMessage={setErrorMsg}
            time={7000}
          />
        </div>
        <div className='mt-4'>
          <CustomAlert
            variant={'danger'}
            alertMessage={accountLockMsg}
            setAlertMessage={setAccountLockMsg}
            time={10000}
          />
        </div>
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Login</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <div className='fw-bold'>
          <Link to='/auth/forgot-password' className='link-primary fw-bolder'>
            Forgot Password?
          </Link>
        </div>
        <div className='text-gray-400 fw-bold fs-4 mt-4'>
          New Here?{' '}
          <a
            href={`${process.env.REACT_APP_B4IGO_LANDING_PAGE}/#pricing`}
            className='link-primary fw-bolder'
          >
            Create an Account
          </a>
        </div>
      </div>
    </form>
  )
}

export default LoginForm
