import React, { useState, useMemo, useEffect, useRef } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import { debounce } from 'lodash'
import { v4 as uuidv4 } from 'uuid';

// import mention from "quill-mention";
import 'react-quill/dist/quill.snow.css'
import 'quill-mention/dist/quill.mention.css'
import 'quill-mention'
import ImageModal from '../components/Comments/ImageModal'



import { useSelector } from 'react-redux'
import { useEditModal } from '../context/ShowEditModalQuill'
import ReactToolTip from './ReactToolTip'

import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.snow.css'
import 'react-tooltip/dist/react-tooltip.css'
import '../styles/richTextStyle.css'
import { useTheme } from '../context/ThemeContext'
import { useAuth } from '../modules/auth'
import { quillBackgroundColors } from '../utils/quillColors'
import { extractMentionText } from '../utils/extractMentionText'

const Size = Quill.import('formats/size')
Size.whitelist = ['extra-small', 'small', 'medium', 'large']
Quill.register(Size, true)

const Font = Quill.import('formats/font')
Font.whitelist = ['arial', 'comic-sans', 'courier-new', 'georgia', 'helvetica', 'lucida']
Quill.register(Font, true)

const Link = Quill.import('formats/link')
Link.sanitize = function(url:any) {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`
  }
  return url;
}

// const Block = Quill.import('pa')
// Block.tagName = 'P'
// Block.className = 'spacer'
// Quill.register(Block, true)

// Quill.register('modules/mention', mention, true);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
interface TextEditorProps {
  setEditorTags?: React.Dispatch<React.SetStateAction<any | null>>
  setEditor: React.Dispatch<React.SetStateAction<string | null>>
  editor: string | null
  placeholder: string
  forTitle?: string
  tocHeading?: any
  role?: string
  boardId?: string
  tabIndex?: any
  onFocus?: any
  handleFormSubmission?: () => void
  optionsForSendAlert ?: any
  setSendAlertTo ?: any
  // sendAlertTo ?: any
  setDesc ?: any
  setMentions ?: any
  retainMentions ?: any
  uniqueId ?:any
}

const MemoizedTextEditor: React.FC<TextEditorProps> = ({
  setEditor,
  editor,
  placeholder,
  tocHeading,
  // forTitle,
  role,
  boardId,
  tabIndex,
  onFocus,
  optionsForSendAlert,
  // setSendAlertTo,
  // sendAlertTo,
  // setDesc,
  handleFormSubmission, setEditorTags = () => { },
  setMentions,retainMentions,
  uniqueId
}): any => {
  const { setShowEditModalQuill, setCard } = useEditModal()
  // const { setShowEditPageModalQuill, setPage } = useEditPageModal()
  const editRef: any = useRef()
  const quillRef: any = useRef(null)
  const { theme } = useTheme()
  const {currentUser}= useAuth()
  const {fullName}= currentUser?.data.user
  useEffect(() => {
    if (quillRef.current && onFocus) {
        quillRef.current.focus();  // Focus editor when triggered
    }
}, [onFocus]);

  const [isVideoSrcGet, setIsVideoSrcGet] = useState(false)
  const [cardSelected, setCardSelected] = useState<any>(null)
  const [pageSelected, setPageSelected] = useState<any>(null)

  // const [cursorRange, setCursorRange] = useState({ min: null, max: null,start: null });
  // const { allCurrentUserCards } = useSelector((state: any) => state.cardReducer)
  const { pagesByLoggedInUser:pages } = useSelector((state: any) => state.pageReducer)
  const {allCurrentUserCards} = useSelector((state: any) => state.cardReducer)

  const handleMouseover = (id: string) => {
    const cardInfo: any = allCurrentUserCards?.find((card: any) => `${card?.key.trim()}` === id)
    const pageInfo: any = pages?.find((page: any) =>  `${page?.id}` === id)
    setShowEditModalQuill(false)
    // setShowEditPageModalQuill(false)
    if (cardInfo) {
      setCardSelected(cardInfo)
    }
    if (pageInfo) {
      setPageSelected(pageInfo)
    }
  }

  const debouncedOnChange = debounce(handleMouseover, 300)

  const handleChange = (content: string, delta: any, source: string) => {
    if (source === 'user') {

      // CREATE CUSTOM LINKS 

      // Apply the collected updates
      // updates.forEach(({ index, length, link, endIndex }:any) => {
      //   // Apply link formatting
      //   quill.formatText(index, length, 'link', link);
    
      //   // Add a space after the link if needed
      //   const nextChar = quill.getText(endIndex, 1);
      //   if (nextChar !== ' ' && nextChar !== '') {
      //     quill.insertText(endIndex, ' ');
      //   }
      // })

        // const text = quill.getText(); // Get the editor content

        // const urlRegex = /((http:\/\/|https:\/\/|www\.)[^\s]+)/g;
        // let match;
        // while ((match = urlRegex.exec(text)) !== null) {
        //   const matchedText = match[0];  // Matched URL
        //   const startIndex = match.index; // Starting index of the URL in the text

        //   // If it starts with www., add the http:// prefix
        //   const fullUrl = matchedText.startsWith('www.')
        //     ? `http://${matchedText}`
        //     : matchedText;

        //   // Apply link formatting asynchronously
        //   quill.formatText(startIndex, matchedText.length, 'link', fullUrl);
        // }
      if(boardId){
        const replacedContent = content.replace(/\/[^#\s]*\d[^\s]*/g, (match) => {
          const replacedContent1 = match.replace(/amp;/g, '');
          const matchingCard = allCurrentUserCards?.find((card: any) => {
            const cardPattern = `/${card?.key?.trim()}`
            return cardPattern === replacedContent1
          })
          if (matchingCard) {
            return `<a href="#">${match}</a>`
          } else {
            return match
          }
        })
        const replacedContentForPage = content.replace(/\/[^#\s]*\d[^\s]*/g, (match) => {
          const replacedContent1 = match.replace(/amp;/g, '');
          const matchingPage = pages?.find((page: any) => {
            const pagePattern = `/${page?.key?.trim()}`
            return pagePattern === replacedContent1
          })
          if (matchingPage) {
            return `<a href="#">${match}</a>`
          } else {
            return match
          }
        })
  
        setEditor(replacedContent)
        setEditor(replacedContentForPage)
        setMentions&& extractMentionText(content,setMentions,retainMentions)

      }else{
        setEditor(content)
      }
    }

    const customLinks = document.querySelectorAll<HTMLAnchorElement>(
      'div.ql-editor[contenteditable="true"] a'
    )

    if (customLinks.length > 0) {
      Array.from(customLinks).forEach((link) => {
        if (/\/\S*-\d+\b/.test(link.innerText)) {
          link.setAttribute(
            'style',
            `max-width: 30%; cursor: pointer; text-decoration: underline; display: inline; color: ${theme === 'dark' ? '#98C6F9' : '#0067c5'};`
          )

          link.setAttribute('id', link.innerText)

          link.classList.add('pattern-class')
          link.addEventListener('mouseover', (e) => {
            e.preventDefault()
            debouncedOnChange(link.innerText.replace(/[/#]/g, '').trim())
          })
          link.setAttribute(
            'data-tooltip-id',
            `my-tooltip-children-multiline-write-${link.innerText.replace(/[#/]/g, '').trim()}`
          );
          

          // link.addEventListener('click', (e) => {
          //   e.preventDefault()
          //   if (handleFormSubmission) {
          //     debugger
          //     handleFormSubmission()
          //   }
          //   if(!linkClickLoading){
          //     setCard(
          //       allCurrentUserCards?.find(
          //         (card: any) => `${card?.key.trim()}` === link.innerText.replace('/', '').trim()
          //       )
          //     )
  
          //     setShowEditModalQuill(true)
          //     setCardSelected(null)
          //   }
           
          // })
        }
      })
    }

    const mentionsEle = document.querySelectorAll<HTMLAnchorElement>(
      'div.ql-editor[contenteditable="true"] .mention'
    )

    Array.from(mentionsEle).forEach((link) => {
      link.setAttribute(
        'data-tooltip-id',
        `my-tooltip-children-multiline-write-${link
          ?.querySelector('span')
          ?.innerHTML.trim()
          ?.replace(/[#/]/g, '')}`
      )
    })

    handleMedia()     
  }

  const onBlur = (previousSelection: any, source: any, editor: any) => {
    const content = editor.getHTML()

    const upContent = content.replaceAll('</p>', '  </p>')
    const nextCont = upContent.replaceAll('<p>', '  <p>  ')
    if (source === 'user') {
      if(boardId){
        const replacedContent = nextCont.replace(/\/[^#\s]*\d[^\s]*/g, (match: any) => {
          const matchingCard = allCurrentUserCards?.find((card: any) => {
            const cardPattern = `/${card?.key.trim()}`
            return cardPattern === match
          })
          if (matchingCard) {
            return `<a href="#">${match}</a>`
          } else {
            return match
          }
        })
       
        const replacedContentForPage = nextCont.replace(/\/[^#\s]*\d[^\s]*/g, (match:any) => {
          const replacedContent1 = match.replace(/amp;/g, '');
          const matchingPage = pages?.find((page: any) => {
            const pagePattern = `/${page?.key?.trim()}`
            return pagePattern === replacedContent1
          })
          if (matchingPage) {
            return `<a href="#">${match}</a>`
          } else {
            return match
          }
        })
        setEditor(replacedContent)
        setEditor(replacedContentForPage)
        setMentions && extractMentionText(content,setMentions,retainMentions)

      }else{
        setEditor(content)
      }

    }
    const customLinks = document.querySelectorAll<HTMLAnchorElement>(
      'div.ql-editor[contenteditable="true"] a'
    )


    if (customLinks.length > 0) {
      Array.from(customLinks).forEach((link) => {
        if (/\/\S*-\d+\b/.test(link.innerText)) {
          link.setAttribute(
            'style',
            `max-width: 30%; cursor: pointer; text-decoration: underline; display: inline; color: ${theme === 'dark' ? '#98C6F9' : '#0067c5'};`
          )

          link.setAttribute('id', link.innerText)

          link.classList.add('pattern-class')
          link.addEventListener('mouseover', () =>{
            debouncedOnChange(link.innerText.replace(/#]/g, '').trim())
          })
          link.setAttribute(
            'data-tooltip-id',
            `my-tooltip-children-multiline-write-${link.innerText.replace(/[/#]/g, '').trim()}`
          );
          

          // link.addEventListener('click', (e) => {
          //   e.preventDefault()
          //   if (handleFormSubmission) {
          //     handleFormSubmission()
          //   }
          //   if(!linkClickLoading){
          //     setCard(
          //       allCurrentUserCards?.find(
          //         (card: any) => `${card?.key.trim()}` === link.innerText.replace('/', '').trim()
          //       )
          //     )
  
          //     setShowEditModalQuill(true)
          //     setCardSelected(null)
          //   }
          // })
        }
      })
    }
  }

  function handleMedia() {
    const editImg: any = document.querySelectorAll(`div.ql-editor[contenteditable="true"] img`)
    const videoIframe: any = document.querySelectorAll(
      `div.ql-editor[contenteditable="true"] iframe`
    )

    if (videoIframe.length > 0) {
      setIsVideoSrcGet(true)
    }

    for (let i = 0; i < editImg.length; i++) {
      editImg[i].style.maxWidth = '30%'
      editImg[i].classList.add('m-2')
      editImg[i].classList.add('cursor-pointer')

      editImg[i].addEventListener('click', () => {
        const imageSrc: any = editImg[i].getAttribute('src')
        editRef.current?.handleModal(imageSrc)
      })
    }
  }

  const onHover = (event: any) => {
    handleMouseover(event.value.id)
  }

  const onMentionClick = (event: any) => {
    if(event.value.denotationChar !== '@'){
    if (handleFormSubmission) {
        handleFormSubmission()
          if(event.value.denotationChar === '/'){
            setCard(
            allCurrentUserCards?.find((card: any) => `${card?.key.trim()}` === (event.value.id?.trim() || event.value.value?.trim())))
          }else{
            setCard(
              pages?.find((page: any) =>  `${page?.id}` === event.value.id)
            )
          }
          setShowEditModalQuill(true)
          setCardSelected(null)
    }
    }
  }

  const mentionDebounceOnHover = debounce(onHover, 300)

  useEffect(() => {
    window.addEventListener('mention-hovered', mentionDebounceOnHover, false)
    window.addEventListener('mention-clicked', onMentionClick, false)
    if (editor) {
      handleChange(editor, 0, 'user')
    }
    return () => {
      window.removeEventListener('mention-hovered', mentionDebounceOnHover, false)
      window.removeEventListener('mention-clicked', onMentionClick, false)
    }
    //eslint-disable-next-line
  }, [])

  const scrollToMention = () => {
    if (editor) {
      const quillEditor = quillRef.current.getEditor();
      const targetMention = [...quillEditor.root.querySelectorAll("p span")].find(span => span.getAttribute("data-unique-id") === uniqueId);
      if (!targetMention) {
        return;
      }
      //scroll to uniqueid mention
      const headingElement = targetMention;
      const rect = headingElement.getBoundingClientRect();
      const editorRect = quillEditor.root.getBoundingClientRect();
      const scrollTop = rect.top - editorRect.top + quillEditor.root.scrollTop;
      quillEditor.root.scrollTop = scrollTop;
    }
  };
  
  const scrollToHeading = () => {
    if (editor) {
      const quillEditor = quillRef.current.getEditor();
      const headingElements = quillEditor.root.querySelectorAll('h1, h2');

      for (let i = 0; i < headingElements.length; i++) {
        if (i === tocHeading?.index) {
          const headingElement = headingElements[i];
          const rect = headingElement.getBoundingClientRect();
          const editorRect = quillEditor.root.getBoundingClientRect();
          const scrollTop = rect.top - editorRect.top + quillEditor.root.scrollTop;

          quillEditor.root.scrollTop = scrollTop;
          break;
        }
      }
    }
  };

  const getHtmlTags = (editor: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(editor, 'text/html');

    const headings = doc.querySelectorAll<HTMLHeadingElement>('h1, h2');

    const combinedTags = Array.from(headings).map((tag, index) => ({
      name: tag.textContent,
      tagName: tag.tagName.toLowerCase(),
      index,
    }));

    setEditorTags(combinedTags);
  }

  useEffect(() => {
    if (editor) {
      getHtmlTags(editor)
    }
  }, [editor])

  useEffect(() => {
    if (tocHeading) {
      scrollToHeading()
    }
  }, [tocHeading, editor])
  useEffect(() => {
    if (uniqueId) {
      scrollToMention()
    }
  }, [uniqueId,editor])


  const formats = useMemo(
    () => [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'align',
      'strike',
      'script',
      'blockquote',
      'background',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video',
      'color',
      'code-block',
      'mention',
    ],
    []
  )

  function CardData({ data }: { data: string }): React.ReactElement {
    return <span dangerouslySetInnerHTML={{ __html: data }} />
  }
  async function suggestPeople(searchTerm: any) {
    const filteredCards=allCurrentUserCards?.filter((c:any)=> c.boardId === boardId)

    const cardIds =
    filteredCards?.length > 0 &&
    filteredCards?.map((card: any) => ({
        id: `${card?.key.trim()}`,
        value: `${card?.key.trim()}`,
      }))

    cardIds?.length > 0 &&
      cardIds?.sort((a: any, b: any) => {
        return a.value.localeCompare(b.value)
      })

    return cardIds?.length > 0 && cardIds?.filter((person: any) => person.value.includes(searchTerm))
  }
  async function suggestPages(searchTerm: any) {
    
    const filteredPages = pages?.filter((p: any) => 
      p.boardId === boardId && 
      p.board_people.find((bp: any) => bp.user.fullName === fullName) &&
      p.key && 
    !p.key.includes("undefined")
  );

    const pageIds = filteredPages
    ?.filter((page: any) => page?.key) 
    ?.map((page: any) => ({
      id: page.id,
      value: page.key.trim(),
    }));
  

      pageIds?.length > 0 &&
      pageIds?.sort((a: any, b: any) => {
        return a.value.localeCompare(b.value)
      })

    return pageIds?.length > 0 && pageIds?.filter((person: any) => person.value.includes(searchTerm))
  }
  async function suggestAlertOptions(searchTerm: any) {
    const options = optionsForSendAlert
    ?.map((option: any) => ({
      id: option.value,
      value: option.label,
    }));
  
    options?.length > 0 &&
    options?.sort((a: any, b: any) => {
        return a.value.localeCompare(b.value)
      })
    return options?.length > 0 && options?.filter((option: any) => option.value.includes(searchTerm))
  }
   // Handlers for custom buttons
   const handleCustomMentionTrigger = (char: '@ ' | '/ ' | '# ') => {
    const quill = quillRef.current?.getEditor();
    if (!quill) return;
  
    const range = quill.getSelection();
    if (!range) return;
  
    quill.focus(); // Ensure the editor is active
  
    // ✅ Insert the mention character and move the cursor forward
    quill.insertText(range.index, char);
    quill.setSelection(range.index + 1);
  
    // ✅ Simulate a real keypress event
    setTimeout(() => {
      const keyMap: Record<string, number> = {
        '@': 50, // KeyCode for '@'
        '/': 191, // KeyCode for '/'
        '#': 51, // KeyCode for '#'
      };
  
      const event = new KeyboardEvent('keydown', {
        key: char,
        code: `Digit${keyMap[char]}`,
        keyCode: keyMap[char],
        which: keyMap[char],
        bubbles: true,
      });
  
      quill.root.dispatchEvent(event);
    }, 100);
  };
  
    const modules = useMemo(
      () => ({
        toolbar: 
        {container:
          [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            ['blockquote'],
            [{ direction: 'rtl' }], // text direction
            [{ align: [] }],
            [{ color: [] }, { background: quillBackgroundColors }], // dropdown with defaults from theme
            // [{ font: [] }],
            ['image'],
            ['video'],
            ['link'],
            ['clean'],
            ...(boardId
              ? [
                  ['customButtonMention'],
                  ['customButtonLink'],
                  ['customButtonPage'],
                ]
              : []),
            ],
            handlers: {
              customButtonMention: () => handleCustomMentionTrigger('@ '),
              customButtonLink: () => handleCustomMentionTrigger('/ '),
              customButtonPage: () => handleCustomMentionTrigger('# '),
            },
        },
     
      mention: {
        // mentionContainerClass:  theme === 'dark' ? 'theme__div' : '',
        mentionListClass: theme === 'dark' ? 'theme__div' : '',

        allowedChars: /^[A-Za-z\sÅÄÖåäö\d=+\-_)(*&^%$#@![\]{}';:"/.,?><\\|]*$/,
        mentionDenotationChars: ['/','#','@'],
        spaceAfterInsert: false,
        maxChars:300,
        source: async function (searchTerm:any, renderList:any,mentionChar:any) {
          if (mentionChar === '/') {
            const matchedPeople = await suggestPeople(searchTerm.slice(1)); 
            renderList(matchedPeople, searchTerm);
          } else if (mentionChar === '#') {
            const matchedPages = await suggestPages(searchTerm.slice(1)); 
            renderList(matchedPages, searchTerm);
          } else if (mentionChar === '@') {
            const matchedPeople = await suggestAlertOptions(searchTerm.slice(1)); 
            renderList(matchedPeople, searchTerm);
            
          } else {
            renderList([], searchTerm); // No valid character
          }
        },
        onSelect: function (item:any, insertItem:any) {
          insertItem(item); // Insert the mention
         if(item.denotationChar === '@'){
          setTimeout(() => {
            // Modify the inserted mention element
            const mentions = document.querySelectorAll(`[data-id="${item.id}"]`);
            mentions.forEach((mention) => {
              const vvid = uuidv4(); 
              mention.setAttribute('data-unique-id', vvid);
              mention.setAttribute('data-description', 'new');
            });
          }, 0); // Delay to ensure DOM update
         }
        },
      },
    }),
    
    []
  )
  useEffect(() => {
    const quill = quillRef.current.getEditor();
    quill.root.addEventListener('keydown', handleKeyDown);

    return () => {
      quill.root.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleKeyDown = (e:any) => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    if (e.key === 'Tab') {
      if (range) {
          setTimeout(() => {
            quill.removeFormat(range.index+1, 0);
          }, 0); 
      }
    }
  };
  return (
    <>
      <div className={`${'text-editor bg-white '} ${theme === 'dark' ? 'dark-mode-placeholder' : ''}`}>
        <ReactQuill
          theme='snow'
          value={editor ? editor : ''}
          onChange={handleChange}
          placeholder={placeholder}
          modules={modules}
          formats={formats}
          ref={quillRef}
          tabIndex={tabIndex}
          className={`${'image_resize_textEditor'} ${theme === 'dark' ? 'dark__theme__input' : ''
            } `}
          onBlur={onBlur}
          // readOnly={forTitle === 'description' && role?.isGuest === true}
          readOnly={role && role === 'Guest' ? true : false}
        />

        {cardSelected && (
          <ReactToolTip id={`my-tooltip-children-multiline-write-${cardSelected?.key?.trim()}`}>
            <div id='react-tooltip-custom'>
              <div className='custom-card'>
                <h5 className={theme === 'dark' ? 'white__text' : ''} style={{ margin: 0, textDecoration: 'underline' }}>{cardSelected?.card_name}</h5>
                <span className='custom-card-description'>
                  {cardSelected?.description ? (
                    <CardData data={cardSelected?.description} />
                  ) : (
                    <CardData
                      data={`<span>
                    No Description
                  </span>`}
                    />
                  )}
                </span>
              </div>
            </div>
          </ReactToolTip>
        )}
        {pageSelected && (
          <ReactToolTip id={`my-tooltip-children-multiline-write-${pageSelected?.key?.trim()}`}>
            <div id='react-tooltip-custom'>
              <div className='custom-card'>
                <h5 className={theme === 'dark' ? 'white__text' : ''} style={{ margin: 0, textDecoration: 'underline' }}>{pageSelected?.title}</h5>
                <span className='custom-card-description'>
                  {pageSelected?.description ? (
                    <CardData data={pageSelected?.description} />
                  ) : (
                    <CardData
                      data={`<span>
                    No Description
                  </span>`}
                    />
                  )}
                </span>
              </div>
            </div>
          </ReactToolTip>
        )}
      </div>

      {isVideoSrcGet && (
        <p>
          If video is not preview or loaded properly! Kindly disable any ad blockers or
          privacy-related browser extensions temporarily
        </p>
      )}
      <ImageModal ref={editRef} />
    </>
  )
}

export const TextEditor = React.memo(MemoizedTextEditor)
