import React,{ useState, useEffect } from 'react'
import { login } from '../core/_requests'
import { useNavigate, useParams } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { decodeInvitation } from '../../../services/invitation'
import { getAllRoles } from '../../../services/user-lookup'
import CompanyForm from '../../../components/Forms/CompanyForm'
import { Tab, Tabs } from 'react-bootstrap'
import DialogueModal from '../../../components/Modals/DialogueModal'
import { useAuth } from '../core/Auth'
import RegistrationFormIndividual from '../../../components/Forms/RegistrationFormIndividual'
import 'react-phone-input-2/lib/style.css'
import '../../../styles/registerpage.css'
import { useSelector } from 'react-redux'
import { useTheme } from '../../../context/ThemeContext'
import { fetchAllBoards } from '../../../store/slice/all-boards-slice'
import {useDispatch} from 'react-redux'
import {Dispatch} from '@reduxjs/toolkit'
import { sendPushAlert } from '../../../services/notification'
import { fetchAllPagesByLoggedInUser } from '../../../store/slice/page-slice'
import { fetchBoardByUserId } from '../../../store/slice/board-slice'
import { setIsFirstLogin } from '../../../store/slice/tour-slice'
import { getOrganizationforMembrs } from '../../../services/company'
import { fetchAllRoles } from '../../../store/slice/role-slice'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

export function Registration():React.JSX.Element {
  const {theme} = useTheme()
  const [loading, setLoading] = useState(false)

  const [roleList, setRoleList] = useState<any>([])
  const [show, setShow] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const { redirectUrl } = useSelector((state: any) => state.RedirectionReducer)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const dispatch: Dispatch<any> = useDispatch()

  const params: any = useParams()
  const navigate = useNavigate()
  const urlAddress = window.location.search
  const token = urlAddress.slice(7)

  useEffect(() => {
    const auth: any = localStorage.getItem('auth')
    const authData = JSON.parse(auth)

    if (authData) {
      localStorage.clear()
      window.location.reload()
    }
  }, [])

  const decodeTokenHandler : any = async (userId: string) => {
    let payload
    try {
      const decodedResponse = await decodeInvitation({
        token: token || params.token,
        usage: 'boardInvite',
      })
      const decodedData = decodedResponse.data.decodeToken
      payload = {
        email: decodedData.email,
        boardId: decodedData.boardId,
        roleId: decodedData.roleId,
        userId: userId,
        isAdmin: decodedData.role.isAdmin,
        inviteByUserId:decodedData.inviteByUserId
      }
    } catch (error: any) {
      const msg = error.response.data.message
      if (msg === 'jwt expired') {
        navigate('/error/404')
      }
    }

    return payload
  }

  const getRolesHandler = async () => {
    try {
      const roleRes = await getAllRoles()
      setRoleList(roleRes.data.data)
    } catch (error) {
      return error
    }
  }

  const loginHandler = async (email: string, password: string,boardId:string) => {
    try {
      const { data: auth }: any = await login(email, password)
      setLoading(false)
        const alertData = {
          eventName: 'welcome-alert',
          subscriberEmail: auth.data.user.email,
          subscriberId: auth.data.user.id,
          payload: {
            description:''
          }
        }
        await sendPushAlert(alertData,auth.token)
        if (auth.data.user.isInvitedByOrganization) {
          const organizationDetail = await getOrganizationforMembrs(auth.token);
          if (organizationDetail?.data?.data?.memberOrganization?.orgId) {
            auth.organizationId = organizationDetail.data.data.memberOrganization.orgId;
          }
        }
      if(boardId){
        dispatch(setIsFirstLogin(true));
        dispatch(fetchBoardByUserId({userId: auth.data.user.id,organizationId:auth.organizationId, isAdmin: true, limit: 'all', page: 1}))
        dispatch(fetchAllBoards({userId:auth.data.user.id}))
        dispatch(fetchAllPagesByLoggedInUser({id:auth.data.user.id}))
        navigate(`/board/${boardId}`)
      }else{
        navigate(redirectUrl)
      }
      if (redirectUrl === '/manage-account/licenses') {
        dispatch(fetchAllBoards({userId:auth.data.user.id}))
      }
      dispatch(fetchAllRoles())
      saveAuth(auth)
      setCurrentUser(auth)
    } catch (error: any) {
      return error
    }
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    getRolesHandler()
    //eslint-disable-next-line
  }, [])

  return (
    <>

      <div className='mb-2 text-center '>
 <div className='d-flex align-items-center justify-content-center gap-5'>
    <h1 className={` ${theme === 'dark' ? 'white__text' : 'text-dark'} mt-7`}>
      Sign Up to <span className='fw-semibold'>B4i</span>
      <span className='text-primary fw-semibold'>GO</span>
    </h1>
      <a href={process.env.REACT_APP_B4IGO_LANDING_PAGE} className=''>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/b4igo-go.png')}
                className='h-100px w-90px my-5 me-15'
              />
            </a>
  </div>
      </div>
      <p className='text-muted my-7'>
        Let&apos;s get underway! Signup first, road test b4igo and if the love is there, enter the card
        details later.
      </p>

      <Tabs
        defaultActiveKey='individual'
        id='uncontrolled-tab-example'
        className='mb-3 text-center fs-6 
      
         '
         //bg-white
      >
        <Tab eventKey='individual' title='Signup as an individual' className='bg-white'>
          <RegistrationFormIndividual
            setLoading={setLoading}
            loading={loading}
            roleList={roleList}
            loginHandler={loginHandler}
            decodeTokenHandler={decodeTokenHandler}
            handleClose={handleClose}
            handleShow={handleShow}
          />
        </Tab>
        <Tab eventKey='organisation' title='Company / Organisation Signup' className='bg-white'>
          
          <CompanyForm        
            roleList={roleList}
            loginHandler={loginHandler}
            decodeTokenHandler={decodeTokenHandler}
            handleClose={handleClose}
            handleShow={handleShow}
          />
        </Tab>
      </Tabs>

      <DialogueModal show={show} handleClose={handleClose} />

    </>
  )
}
