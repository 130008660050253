import { findMentionOccurrence, getTextAfterNthMention } from "./findMention";

type Mention = {
    user: string | null;
    id: string | null;
    text: string;
};
export function extractMentionText(
    input: string,
    setMentions: (mentions: Mention[]) => void,
    retainMentions: any[]
): void {
    // Parse the string into a DOM document
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');

    // Select all <p> elements in the parsed document
    const paragraphs: any = doc.querySelectorAll('p');

    // for new mentions
    let currentUser: string | null = null;
    let currentText = "";
    let currentId: string | null = null;
    let uniquecurrentId: string | null = null;

    // for other mentions
    let othercurrentUser: string | null = null;
    let othercurrentText = "";
    let othercurrentId: string | null = null;
    let otheruniquecurrentId: string | null = null;
    const result: any[] = [];
    const otherMentions: any[] = [];
    let multipleMentions: any[] = [];
    let multipleEditMentions: any[] = [];
    let dont = false
    let editDont = false


    // Process each paragraph
    paragraphs.forEach((paragraph: any, paragraphIndex: any) => {
        const isBreak = paragraph.innerHTML === "<br>"; // Detect a single line break
        const mentionSpans = paragraph.querySelectorAll('span.mention[data-denotation-char="@"]'); // Check for mention class 
        mentionSpans?.forEach((mentionSpan: any, index: any) => {
            if (!isBreak) {
                // Check if mention is new or old
                if (mentionSpan && mentionSpan.getAttribute('data-description') === "new") {
                    // If there's an active user, push the accumulated text for that user
                    if (currentUser && !dont) {
                        result.push({ user: currentUser, id: currentId, uniqueId: uniquecurrentId, text: currentText.trim() });
                    }

                    // Update the current user and reset text
                    currentUser = mentionSpan.getAttribute('data-value');
                    currentId = mentionSpan.getAttribute('data-id');
                    uniquecurrentId = mentionSpan.getAttribute('data-unique-id');
                    const occurrenceCount = findMentionOccurrence(mentionSpans, currentUser || '', uniquecurrentId || '');

                    //Check if next span has any text to add in prev user text
                    let nextContent = null;
                    if (mentionSpans[index + 1]) {
                        nextContent = mentionSpans[index + 1].textContent || '';
                    }
                    currentText = getTextAfterNthMention(paragraph.textContent || '', mentionSpan?.textContent || '', occurrenceCount);

                    if (nextContent) {
                        currentText = currentText?.split(nextContent)[0]
                    }


                    // Check if next paragraph has any text to add in current user text
                    let nextParagraphIndex = paragraphIndex + 1;
                    //if it is last span of a paragraph check for text in next paragraph
                    if (index + 1 === mentionSpans.length) {
                        while (
                            nextParagraphIndex < paragraphs.length && paragraphs &&
                            paragraphs[nextParagraphIndex].innerHTML !== "<br>"
                        ) {
                            // if next para has a mention stop getting text before that
                            const nextLineMentionSpan = paragraphs[nextParagraphIndex].querySelectorAll('span.mention[data-denotation-char="@"]');
                            if (nextLineMentionSpan[0]) {
                                currentText += ` ${paragraphs[nextParagraphIndex]?.textContent.split(nextLineMentionSpan[0]?.innerText)[0]} `
                                break;
                            } else {
                                // else get all the text
                                currentText += ` ${paragraphs[nextParagraphIndex]?.textContent.trim()}`;
                                nextParagraphIndex++;
                            }
                        }
                    }
                    if (mentionSpans[index + 1] && paragraph.textContent
                        .split(mentionSpans[index].textContent)[1] // First split
                        .split(mentionSpans[index + 1]?.textContent || '')[0] // Second split
                        .trim().length <= 0) {
                        dont = true;

                        multipleMentions.push({
                            user: currentUser,
                            id: currentId,
                            uniqueId: uniquecurrentId,
                            text: null
                        });
                        // return;
                    }
                    else {
                        dont = false;
                        multipleMentions.forEach(mention => {
                            result.push({
                                user: mention.user,
                                id: mention.id,
                                uniqueId: mention.uniqueId,
                                text: currentText.trim()
                            });
                        });
                        multipleMentions = [];
                    }
                }

                if (mentionSpan && mentionSpan.getAttribute('data-description') !== "new") {
                    // Check if mention is old
                    if (othercurrentUser && !editDont) {
                        otherMentions.push({ user: othercurrentUser, id: othercurrentId, uniqueId: otheruniquecurrentId, text: othercurrentText.trim() });
                    }

                    // Update the current user and reset text
                    othercurrentUser = mentionSpan.getAttribute('data-value');
                    othercurrentId = mentionSpan.getAttribute('data-id');
                    otheruniquecurrentId = mentionSpan.getAttribute('data-unique-id');
                    const occurrenceCount = findMentionOccurrence(mentionSpans, othercurrentUser || '', otheruniquecurrentId || '');


                    //Check if next span has any text to add in prev user text
                    let nextContent = null;
                    if (mentionSpans[index + 1]) {
                        nextContent = mentionSpans[index + 1].textContent || '';
                    }
                    othercurrentText = getTextAfterNthMention(paragraph.textContent || '', mentionSpan?.textContent || '', occurrenceCount);

                    // othercurrentText = (paragraph.textContent || '').split(mentionSpan?.textContent || '')[1].replace(mentionSpan.textContent || '', '').trim();
                    // Check if next paragraph has any text to add in current user text
                    if (nextContent) {
                        othercurrentText = othercurrentText?.split(nextContent)[0].trim()
                    }

                    let nextParagraphIndex = paragraphIndex + 1;
                    //if it is last span of a paragraph check for text in next paragraph
                    if (index + 1 === mentionSpans.length) {
                        while (
                            nextParagraphIndex < paragraphs.length && paragraphs &&
                            paragraphs[nextParagraphIndex].innerHTML !== "<br>"
                        ) {
                            // if next para has a mention stop getting text before that
                            const nextLineMentionSpan = paragraphs[nextParagraphIndex].querySelectorAll('span.mention[data-denotation-char="@"]');
                            if (nextLineMentionSpan[0]) {
                                othercurrentText += ` ${paragraphs[nextParagraphIndex]?.textContent.split(nextLineMentionSpan[0]?.innerText)[0].trim()} `
                                break;
                            } else {
                                // else get all the text
                                othercurrentText += ` ${paragraphs[nextParagraphIndex]?.textContent.trim()}`;
                                nextParagraphIndex++;
                            }
                        }
                    }
                    if (mentionSpans[index + 1] && paragraph.textContent
                        .split(mentionSpans[index].textContent)[1] // First split
                        .split(mentionSpans[index + 1]?.textContent || '')[0] // Second split
                        .trim().length <= 0) {
                        editDont = true;

                        multipleEditMentions.push({
                            user: othercurrentUser,
                            id: othercurrentId,
                            uniqueId: otheruniquecurrentId,
                            text: null
                        });
                        return;
                    }
                    else {
                        editDont = false;
                        multipleEditMentions.forEach(mention => {
                            otherMentions.push({
                                user: mention.user,
                                id: mention.id,
                                uniqueId: mention.uniqueId,
                                text: othercurrentText.trim()
                            });
                        });
                        multipleEditMentions = []
                    }
                }
            }
            // Check if there is a line break
            else if (isBreak && currentUser) {
                // Push text up to the line break and reset currentUser
                result.push({ user: currentUser, id: currentId, uniqueId: uniquecurrentId, text: currentText.trim() });
                currentUser = null;
                currentId = null;
                uniquecurrentId = null;
                currentText = ""; // Reset text after the line break
            }
            else if (currentUser) {
                // Append text to the current user if no mention or break is found
                currentText += ` ${(paragraph.textContent || '').trim()}`;
            }
        })
    });

    // Push the last user's text
    if (currentUser && !dont) {
        result.push({ user: currentUser, id: currentId, uniqueId: uniquecurrentId, text: currentText.trim() });
    }
    if (othercurrentUser && !editDont) {
        otherMentions.push({ user: othercurrentUser, id: othercurrentId, uniqueId: otheruniquecurrentId, text: othercurrentText.trim() });
    }

    function findNewText(arr1: any, arr2: any) {
        return arr1?.map((item1: any) => {
            const match = arr2?.find((item2: any) => item2.uniqueId === item1.uniqueId);
            if (match) {
                // Check if the text has changed
                if (item1.text.trim() !== match.text.trim()) {
                    //Send Only edited text
                    // const originalWords = match.text.split(/\s+/); // Split text into words
                    // const updatedWords = item1.text.split(/\s+/);
                    // const newWords = updatedWords.filter((word: any) => !originalWords.includes(word)); // Find new words
                    // if (newWords.length > 0) {
                    //     result.push({ user: item1.user, id: item1.id, uniqueId: item1.uniqueId, text: newWords.join(" ").trim() });
                        
                    // }
                    //Send complete text
                    result.push({ user: item1.user, id: item1.id, uniqueId: item1.uniqueId, text: item1.text.trim() });
                }
            }
        });
    }
    // Match the new mentions with the old mentions
    if (retainMentions?.length > 0) {
        findNewText(otherMentions, retainMentions);
    }


    setMentions(result)
}