import { useEffect, useState } from "react";

const useVersioning: any = () => {

  const [currentVersion, setCurrentVersion] = useState<any>(null);
  const [versionContent, setVersionContent] = useState<any>(null);
  const [reload, setReload] = useState<any>(false);
  const checkForUpdates = async () => {
    try {
      // eslint-disable-next-line
      // debugger
      const response = await fetch('/version.json', { cache: 'no-cache' });
      const latestData = await response.json();


      if (currentVersion && latestData.version !== currentVersion) {
        setVersionContent(latestData.versionContent)
        setReload(true)
        // if (window.confirm('New updates of the app is available. Please refresh the page.')) {
        //   window.location.reload(); // Reload the app to get the latest version
        // }
      }
    } catch (error) {
      //   console.error('Error checking for updates:', error);
    }
  };
  // eslint-disable-next-line
  // console.log('Version---->', currentVersion)
  // eslint-disable-next-line
  // console.log('Version Content---->', versionContent)
  useEffect(() => {
    // Fetch the current version on mount
    const fetchInitialVersion = async () => {
      try {
        // eslint-disable-next-line
        // debugger
        const response = await fetch('/version.json', { cache: 'no-cache' });
        const initialData = await response.json();
        setCurrentVersion(initialData.version);
        setVersionContent(initialData.versionContent)
      } catch (error) {
        // console.error('Error fetching initial version:', error);
      }
    };

    fetchInitialVersion();

    const interval = setInterval(() => {
      checkForUpdates();
    }, 60000); // 60 seconds

    return () => clearInterval(interval);
  }, [currentVersion]);

  return { reload, setReload, versionContent }
}


export default useVersioning;