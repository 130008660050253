import clsx from 'clsx'
import React, { useState } from 'react'
import { TextEditor } from '../TextEditor'
// import SendAlertTo from '../Select/SendAlert'
import CustomAlert from '../CustomAlert'
import SelectBoard from '../Select/SelectBoard'
import SelectStatus from '../Select/SelectStatus'
import Skeleton from 'react-loading-skeleton'
import AssignTo from '../Select/AssignTo'
import SelectTags from '../Select/SelectTags'
import { removeDuplication } from '../../helper-functions/CapitalizeName'
import { formatDateAndTime } from '../../helper-functions/TimeFormate'
import { FileUploadPage } from '../FileUploader/FileUpload'

const EditPageForm: React.FC<any> = ({ theme, formik, editor, setEditor, updatedDate, boardId, setBoardId, allBoards, loading, tags, setSelectedTags, selectedTags, boardPerson,  optionsForSendAlert, isUserAssignLoading, selected, setSelected, options,  selectedOption, setSelectedOption, loadingDesc, transformedTags, deleteFileHandler,selectedFile,
    // setSendAlertMsg, sendAlertMsg,sendAlertTo, setSendAlertTo,
    changeHandler, setOpenInviteModal,
    socialMediaContent, setsocialMediaContent, otherLinksContent, setOtherLinksContent,
    fileErrorMsg,
    setFileErrorMsg, editorTags, setEditorTags,tocHeading, setTocHeading,imageErrorMsg,setImageErrorMsg ,
    setSocialMediaMentions,setNotesMentions,setOtherLinksMentions,retainNotesMentions,retainSocialLinksMentions,retainOtherLinksMentions,uniqueId
}) => {

    const formattedDate = formatDateAndTime(updatedDate)
    const [activeTab, setActiveTab] = useState<string>('notes');
    const [showTooltip, setShowTooltip] = useState(false);

    const inputStyle = {
        backgroundColor: theme === 'dark' ? '#2C3A4D' : '#ffffff',
        color: theme === 'dark' ? '#F4FFFD' : '#17181f',
        border: `1px solid ${theme === 'dark' ? 'hsl(0, 0%, 80%)' : '#ccc'}`,
        borderRadius: '4px',
        padding: '8px 12px',
        boxShadow: `0 0 0 1px ${theme === 'dark' ? '#333' : '#ccc'}`,
        outline: 'none',
        margin: '2px'
    }

    return (
        <form className='form row' onSubmit={formik.handleSubmit} noValidate>
            <div className='col-12 mb-2 pe-0'>
                <label
                    className={`form-label fs-6 fw-bolder ${theme === 'dark' ? 'white__text' : 'text-dark'
                        } `}
                >
                    Title
                </label>
                <input
                    autoFocus
                    {...formik.getFieldProps('title')}
                    disabled={boardPerson?.role === 'Guest'}
                    className={clsx(
                        `form-control form-control-solid ${theme === 'dark' ? 'dark__theme__input' : ''}`,
                        { 'is-invalid': formik.touched.title && formik.errors.title },
                        {
                            'is-valid': formik.touched.title && !formik.errors.title,
                        }
                    )}
                    type='name'
                    name='title'
                    autoComplete='off'
                    maxLength={300}
                    placeholder='Enter Page Name'
                    tabIndex={1}
                />
                {formik.touched.title && formik.errors.title && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert' className='text-danger'>
                            {formik.errors.title}
                        </span>
                    </div>
                )}
            </div>
            <div className='col-md-12 col-lg-8 col-sm-12'>
                <div className='row mb-5'>

                    <div className='col-md-12 col-lg-12 col-sm-12'>
                        <label
                            className={`form-label fs-6 fw-bolder ${theme === 'dark' ? 'white__text' : 'text-dark'
                                } `}
                        >
                            Website/App Name
                        </label>
                        <input
                            {...formik.getFieldProps('webAppName')}
                            disabled={boardPerson?.role === 'Guest'}
                            className={clsx(
                                `form-control form-control-solid ${theme === 'dark' ? 'dark__theme__input' : ''}`,
                                { 'is-invalid': formik.touched.webAppName && formik.errors.webAppName },
                                {
                                    'is-valid': formik.touched.webAppName && !formik.errors.webAppName,
                                }
                            )}
                            type='name'
                            name='webAppName'
                            autoComplete='off'
                            maxLength={300}
                            placeholder='Enter Website/App Name'
                            tabIndex={2}
                        />
                        {formik.touched.webAppName && formik.errors.webAppName && (
                            <div className='fv-plugins-message-container'>
                                <span role='alert' className='text-danger'>
                                    {formik.errors.webAppName}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className="d-flex gap-3">
                    <div className='d-flex align-items-center gap-2'>
                        <h6
                            className={`my-3 cursor-pointer  ${theme === 'dark' ? ` ${activeTab === 'notes' ? 'text-white' : 'active_tab'}` : `${activeTab === 'notes' ? '' : 'active_tab'}`} 
                           `}
                            onClick={() => setActiveTab('notes')}
                        >
                            Notes
                        </h6>
                        <div
                            className="tooltip-container cursor-pointer"
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                        >
                            <i className={`fa fa-bars ${theme === 'dark' ? 'theme__div' : ''}`}></i>
                            {showTooltip && editorTags?.length > 0 && <span className="tooltiptext">
                                <div className='head fs-2 fw-bolder px-2 mb-2'>Contents of this page</div>
                                {editorTags?.map((tag: any) => (
                                    tag?.name?.length > 0 && (
                                        <div
                                            key={tag.name}
                                            onClick={() => {
                                                setTocHeading(tag);
                                                setShowTooltip(false);
                                            }}
                                        >
                                            <div className={`${tag?.tagName === 'h1' ? 'fs-4' : 'fs-4 ms-8'} cursor-pointer my-3 headings`}>
                                                {tag.name}
                                            </div>
                                        </div>
                                    )
                                ))}

                            </span>}
                        </div>
                    </div>
                    <h6
                        className={`my-3 cursor-pointer ${theme === 'dark' ? ` ${activeTab === 'socialMedia' ? 'text-white' : 'active_tab'}` : `${activeTab === 'socialMedia' ? '' : 'active_tab'}`}`}
                        onClick={() => setActiveTab('socialMedia')}
                    >
                        Social Media Links
                    </h6>
                    <h6
                        className={`my-3 cursor-pointer ${theme === 'dark' ? ` ${activeTab === 'otherLinks' ? 'text-white' : 'active_tab'}` : `${activeTab === 'otherLinks' ? '' : 'active_tab'}`}`}
                        onClick={() => setActiveTab('otherLinks')}
                    >
                        Other Links
                    </h6>
                </div>
                {(loadingDesc || isUserAssignLoading) ?
                    <React.Fragment>
                        <Skeleton count={1} height={35} />
                        <Skeleton count={1} height={155} />
                    </React.Fragment> :
                    <>
                        {activeTab === 'notes' && (
                            <TextEditor tabIndex={3} handleFormSubmission={formik.handleSubmit} boardId={boardId} setEditorTags={setEditorTags} role={boardPerson?.role} placeholder={'Write something'} setEditor={setEditor} editor={editor} tocHeading={tocHeading} optionsForSendAlert={optionsForSendAlert} setMentions={setNotesMentions} retainMentions={retainNotesMentions}  uniqueId={uniqueId}  />
                        )}

                        {activeTab === 'socialMedia' && (
                            <TextEditor tabIndex={3} handleFormSubmission={formik.handleSubmit} boardId={boardId} role={boardPerson?.role} placeholder={'Write something'} setEditor={setsocialMediaContent} editor={socialMediaContent} optionsForSendAlert={optionsForSendAlert} setMentions={setSocialMediaMentions} retainMentions={retainSocialLinksMentions} uniqueId={uniqueId} />
                        )}

                        {activeTab === 'otherLinks' && (
                            <TextEditor tabIndex={3} handleFormSubmission={formik.handleSubmit} boardId={boardId} role={boardPerson?.role} placeholder={'Write something'} setEditor={setOtherLinksContent} editor={otherLinksContent} optionsForSendAlert={optionsForSendAlert}  setMentions={setOtherLinksMentions} retainMentions={retainOtherLinksMentions} uniqueId={uniqueId} />
                        )}
                    </>
                }
                    <CustomAlert
                        variant={'warning'}
                        className={'mt-2 w-100'}
                        alertMessage={imageErrorMsg}
                        setAlertMessage={setImageErrorMsg}
                        />

                {/* <h6 className={`mt-6  ${theme === 'dark' ? 'white__text' : 'text-dark'}`}>
                    Send Alert To:
                </h6>
                <div className='row my-2'>
                    <div className='col-md-9'>
                        {isUserAssignLoading ? (
                            <Skeleton count={1} height={40} />
                        ) :
                            (<SendAlertTo
                                role={boardPerson?.role}
                                options={optionsForSendAlert}
                                setSelected={setSendAlertTo}
                                selected={sendAlertTo}
                            />)}
                    </div>
                    <div className='col-md-3 col-sm-12 alert-btn'>
                        <button
                            className='btn btn-info px-2 pt-3 pb-2'
                            type='button'
                            onClick={() => {
                                if (sendAlertTo.length > 0) {
                                    if (editor === null) {
                                        setSendAlertMsg('Please enter description for send alert.')
                                    }
                                    else {
                                        formik.handleSubmit()
                                    }
                                }
                            }}
                        >
                            Send Alert
                        </button>
                    </div>
                    <div className='col-md-10'>
                        <CustomAlert
                            variant={'warning'}
                            className={'mt-2'}
                            alertMessage={sendAlertMsg}
                            setAlertMessage={setSendAlertMsg}
                        />
                    </div>
                </div> */}
                <div className='row my-2'>
                    <div className='col-md-12'>
                        <FileUploadPage
                            deleteFileHandler={deleteFileHandler}
                            selectedFile={selectedFile}
                            changeHandler={changeHandler}
                        />
                        <CustomAlert
                            variant={'warning'}
                            className={'mt-2 w-75'}
                            alertMessage={fileErrorMsg}
                            setAlertMessage={setFileErrorMsg}
                            time={5000}
                        />
                    </div>

                </div>

            </div>
            <div className='col-md-12 col-lg-4 col-sm-12 border px-4 py-3'>
                <div className='col-12 my-2'>
                    <label className={`${theme === 'dark' ? 'white__text' : 'text-dark'} form-label fs-6 fw-bolder `}>Board</label>
                    <SelectBoard
                        role={boardPerson?.role}
                        boards={allBoards}
                        setBoardId={setBoardId}
                        boardId={boardId}
                    />
                </div>
                <div className='col-12 my-2'>
                    <label className={`${theme === 'dark' ? 'white__text' : 'text-dark'} form-label fs-6 fw-bolder `}>Status</label>
                    <SelectStatus role={boardPerson?.role} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
                </div>
                <div className='col-12 my-2'>
                    <h6 className={theme === 'dark' ? 'white__text' : ''}>Members on this page</h6>
                    <div onClick={() => setOpenInviteModal(true)} style={{ color: '#009ef7', cursor: 'pointer' }}>+Invite to this page</div>

                    {isUserAssignLoading ? (
                        <Skeleton count={1} height={40} />
                    ) : (
                        <AssignTo
                            role={boardPerson?.role}
                            page={true}
                            options={options}
                            setSelected={setSelected}
                            selected={removeDuplication(selected)}
                        />
                    )}
                </div>
                <div className='col-12 my-3'>
                    <h6 className={theme === 'dark' ? 'white__text' : ''}>Tags</h6>
                    {loading ? (
                        <Skeleton count={1} height={40} />
                    ) : (
                        <SelectTags role={boardPerson?.role} tags={tags} transformedTags={transformedTags} setSelectedTags={setSelectedTags} selectedTags={selectedTags} />
                    )}

                </div>
                <div className='col-12 my-3'>
                    <h6 className={theme === 'dark' ? 'white__text' : ''}>Date Last Updated</h6>
                    <input
                        type='text'
                        disabled
                        className='form-control w-100'
                        placeholder='dd-mm-yyy'
                        style={inputStyle}
                        value={formattedDate}


                    />
                </div>
            </div>

        </form>
    )
}

export default EditPageForm