import React,{ useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { acceptInvitation, decodeInvitation } from '../../../services/invitation'
import { userLookup } from '../../../services/user-lookup'
import Skeleton from 'react-loading-skeleton'
import CustomAlert from '../../../components/CustomAlert'
import { useTheme } from '../../../context/ThemeContext'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
export function Invitation(): React.JSX.Element {
  const {theme} =useTheme()
  const [decodedDataState, setDecodedDataState] = useState({})
  const [decodedSignupData, setDecodedSignupData] = useState<any>({})
  const [isLoading, setIsLoading] = useState(true)
  const [redirectToRegister, setRedirectToRegister] = useState(false)
  const [invitationErrorMsg, setInvitationErrorMsg] = useState("")
  const [decodedEmail, setDecodedEmail] = useState("")
  const urlAddress = window.location.search
  const token = urlAddress.slice(7)
  const auth: any = localStorage.getItem('auth')
  const authData = JSON.parse(auth)
  const navigate = useNavigate()
  const acceptInvitationHandler = async () => {
    if (redirectToRegister === false) {
      try {
        setIsLoading(true)
        await acceptInvitation(decodedDataState)
        if (authData) {
          setTimeout(() => {
            navigate('/home')
          }, 1000)
        } else {
          setTimeout(() => {
            navigate('/auth')
            setIsLoading(false)
          }, 3000)
        }
      } catch (error: any) {
        const msg = error.response.data.message
        const msgTrim = msg.trim()
        if (msgTrim === 'User is already member of this board') {
          setInvitationErrorMsg("Invitation Already Accepted!")
          setTimeout(() => {
            navigate('/home')
            setIsLoading(false)
          }, 5000)
        }else{
          setInvitationErrorMsg(msgTrim)
          setTimeout(() => {
            navigate('/auth')
            setIsLoading(false)
          }, 5000)
        }
        setIsLoading(false)
        return error
      }
      // finally{
      //     setIsLoading(false)
      //   }
    } else {
      const isGuest = decodedSignupData.role.isGuest || false
      if(isGuest){
        setTimeout(() => {
          navigate(`/auth/${token}/guest-invitation`,{state:{decodedSignupData:decodedSignupData}})
        }, 2000)
      }else{

        
        
        setTimeout(() => {
          navigate(`/auth/${token}/registration`,{state:{decodedDataEmail:decodedEmail,decodedSignupData:decodedSignupData}})
        }, 2000)
      }
    }
  }
  const decodeTokenHandler = async () => {
    try {
      const decodedResponse = await decodeInvitation({ token, usage: 'boardInvite' })
      const decodedData = decodedResponse.data.decodeToken
      setDecodedEmail(decodedData.email)
      setDecodedSignupData(decodedData)
      try {
        const lookupResponse = await userLookup(decodedData.email)
        const payload = {
          email: decodedData.email,
          boardId: decodedData.boardId,
          roleId: decodedData.roleId,
          userId: lookupResponse.data.user.id,
          isAdmin: decodedData.role.isAdmin,
          inviteByUserId:decodedData.inviteByUserId
          
        }
        setDecodedDataState(payload)
      } catch (error: any) {
        if (error.response.status === 404) {
          setRedirectToRegister(true)
        }
        return error
      }
    } catch (error: any) {
      const msg = error.response.data.message
      if (msg === "jwt expired") {
        navigate('/error/404')
      }
    }finally{
      setIsLoading(false)
    }
  }
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    decodeTokenHandler()
    //eslint-disable-next-line
  }, [])
  return (
    <>
      <div className='mb-10 text-center'>
      <div className='d-flex align-items-center justify-content-center gap-5'>
        <h1 className={` mb-3 ${theme === 'dark' ? 'white__text' :'text-dark'} mt-7`}>Accept Invitation</h1>
 <a href={process.env.REACT_APP_B4IGO_LANDING_PAGE} className=''>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/b4igo-go.png')}
            className='h-100px w-90px my-5 me-15'
          />
        </a>
      </div>
      </div>
      <div className='text-center'>
        {
          isLoading&&  <Skeleton count={1} height={35} width={220} />}
            {
          !isLoading&&
          <button
          type='button'
          className='btn btn-lg btn-primary w-50 mb-5'
          onClick={acceptInvitationHandler}
          >
          Accept
        </button>
        }
        <CustomAlert variant={'info'} alertMessage={invitationErrorMsg} setAlertMessage={setInvitationErrorMsg} />
      </div>
    </>
  )
}