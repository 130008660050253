interface MentionSpan {
    getAttribute(attributeName: string): any;
}

export const findMentionOccurrence = (
    mentionSpans: MentionSpan[],
    currentUser: string,
    uniquecurrentId: string
): number => {
    let count = 0; // Counter for the mention occurrences

    for (const mentionSpan of mentionSpans) {
        const occurenceData = mentionSpan.getAttribute('data-value');
        const uniqueId = mentionSpan.getAttribute('data-unique-id');

        if (occurenceData === currentUser) {
            count++; // Increase count when match found
            if (uniqueId === uniquecurrentId) {
                return count;
            }
            // If needed: Do something when a certain occurrence is reached
        }
    }
    return count;
}
export const getTextAfterNthMention = (text: string, mention: string, count: number): string => {
    const parts = text.split(mention);

    if (count > parts.length - 1) {
        return ''; // If count exceeds available occurrences, return empty string
    }

    return parts.slice(count).join(mention).trim();
};
