import React, { useEffect, useState, useRef } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useFormik } from 'formik'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useAuth } from '../../modules/auth'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from '@reduxjs/toolkit'
import { deleteFileAttachment, updateCardById } from '../../services/cards'
import { awsFileUploadConfig } from '../../config/awsFileUpload'
import {
  useBoardPersons,
  useFileUploader,
  // useGetAllBoards,
  useGetColumns,
  useWebSocketHook,
} from '../../hooks'
import { setIsCardUpdated } from '../../store/slice/last-update-slice'
import { getTimeDateFormate } from '../../helper-functions/TimeFormate'
import UpdateCardForm from '../Forms/UpdateCardForm'
import ReactS3Client from 'react-aws-s3-typescript'
import useSendAlert from '../../hooks/useSendAlert'
import * as Yup from 'yup'
import { setIsRender } from '../../store/slice/isRender-slice'
import { webSocketOperations } from '../../constants'
import { capitalizeEachWord } from '../../helper-functions/CapitalizeName'
import { longWordSlicing } from '../../helper-functions/CheckString'
import { fetchAllCardByBoardId, fetchAllCardsofLoggedInUser } from '../../store/slice/card-slice'
// import EditCardSkeleton from '../../components/Modals/EditCardSkeleton'

import { removeNullFromReminder } from '../../helper-functions/RemoveNullFromReminders'
import { setUpdatedItem, updateSearchData } from '../../store/slice/home-searching-slice'
import useGetDescription from '../../hooks/useGetCardDescription'
import useGetTags from '../../hooks/useGetTags'
import { addBoardTags, removeBoardTags } from '../../store/slice/board-tags-slice'
import { applyColumnRules } from '../../utils/applyColumnRules'

const cardSchema = Yup.object().shape({
  cardName: Yup.string()
    .min(3, 'Minimum 3 Characters')
    .max(300, 'Maximum 300 Characters')
    .required('Task is Required')
    .trim(),

  columnName: Yup.string().required('Column is Required'),
  boardName: Yup.string().required('Board is Required'),
  status: Yup.string().required('Status is Required'),
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EditCardForSearching = ({
  isBoard,
  cardDetails,
  isHomeSearching,
  setSelectedItem,
  handleClose
}: // setIsCardSaved,
  // isCardSaved
  any): React.JSX.Element => {
  const location = useLocation()
  const [disableBtn, setDisableBtn] = useState(false)
  const [isCardSaved, setIsCardSaved] = useState(false)
  // const [editorDescriptionState, setEditorDescriptionState] = useState('')
  const {cardDescription ,setCardDescription, isCardDescriptionLoading,fetchDescription} = useGetDescription(cardDetails.id)
  const [checkIsDescriptionExist, setCheckIsDescriptionExist] = useState(true)

  const [saveBtnProp, setSaveBtnProp] = useState({
    title: 'Save',
    color: 'primary',
  })
  const { tags, loading: tagsLoading } = useGetTags() 
  const transformedTags = (cardDetails?.tags || []).map((tag: any) => ({
    value: tag.id,
    label: tag.name
  }));
  const [selectedTags, setSelectedTags] = useState(transformedTags)
  const [tagsToUpdate, setTagsToUpdate] = useState<any>({
    addedTags: [],
    removedTags: [],
  });
  const [commentsLoading,setCommentsLoading] = useState<boolean>(true)

  const [boardId, setBoardId] = useState(cardDetails.boardId)
  const [columnId, setColumnId] = useState(cardDetails.board_column_id)
  const [cardLength, setCardLength] = useState<number>()
  const [startDate, setStartDate] = useState<any>(
    cardDetails?.due_date && cardDetails?.due_time
      ? new Date(`${cardDetails.due_date}T${cardDetails.due_time}Z`)
      : ''
  )
  const [editorComment, setEditorComment] = useState('')
  const isLastUpdated: any = useSelector((state: any) => state.lastUpdateReducer)
  const [reminders, setReminders] = useState<any>([])
  const [deleteReminderIds, setDeletedReminderIds] = useState<any>([])
  const { isCardUpdated } = isLastUpdated.isCardUpdated

  const dispatch: Dispatch<any> = useDispatch()
  const s3 = new ReactS3Client({
    ...awsFileUploadConfig,
    dirName: `cards-attachments/${process.env.REACT_APP_ENVIRONMENT}`,
  })

  const childCommentRef: any = useRef()
  const params: any = useParams()
  const { currentUser }: any = useAuth()
  const navigate = useNavigate()
  const { id, fullName } = currentUser?.data.user
  // const boardFromReducer = useSelector((state: any) => state.boardReducer)
  // const { data: boards } = boardFromReducer?.entities
  const {allBoards,isBoardLoading} = useSelector((state: any) => state.allBoardsReducer)

  // const { allBoards, isBoardLoading } = useGetAllBoards()
  const {
    columnResponse: columns,
    fetchColumnHandler,
    isColumnLoading,
  } = useGetColumns(boardId || params.boardId)

  const initialValues = {
    cardName: cardDetails.card_name && cardDetails.card_name.trim(),
    dueDate: cardDetails.due_date,
    dueTime: cardDetails.due_time,
    boardName: cardDetails.board_name,
    columnName: cardDetails.board_column_id,
    status: cardDetails.status,
    priority: cardDetails.priority,
  }

  // let board = boards?.filter((item: any) => params.boardId === item.id)
  // let isPageArchiveBoard = window.location.href.includes('archived')
  const { sendJsonMessage }: any = useWebSocketHook()
  const { fileErrorMsg, selectedFile, setSelectedFile, selectFileHandler, setFileErrorMsg } =
    useFileUploader()

  const {
    optionsOfAssignee,
    isUserAssignLoading,
    selectAutoAssignee,
    setSelectAutoAssignee,
    optionsForSendAlert,
    boardPersonsForSendAlert,
    getBoardPersonHandler,
  } = useBoardPersons(cardDetails)
  const isRenderStore = useSelector((state: any) => state.isRenderReducer)
  const { allSearchData } = useSelector((state: any) => state.homeSearchingReducer)


  const { isRender } = isRenderStore.isRender

  const {
    sendAlertTo,
    setSendAlertTo,
    sendAlertToComment,
    setSendAlertToComment,
    sendAlertMsg,
    sendAlertMsgComment,
    sendAlertToBoardPerson,
    setSendAlertMsg,
  } = useSendAlert(
    boardPersonsForSendAlert,
    cardDetails,
    boardId,
    editorComment,
    cardDescription
  )

  const deleteFileHandler = async (name: string, key: string, value: string) => {
    let files: any
    if (key) {
      files = selectedFile.filter((item: any) => item.key !== key)
    } else {
      files = selectedFile.filter((item: any) => item.name !== name)
    }
    try {
      const fileNameForDelete = value || name
      await s3.deleteFile(fileNameForDelete)
      try {
        setSelectedFile(files)
        await deleteFileAttachment(cardDetails.id, key, id, boardId)
      } catch (error) {
        return error
      }
    } catch (error) {
      return error
    }
  }

  const handleSubmission = async () => {
    const urls = []
    for (let index = 0; index < selectedFile.length; index++) {
      const filename = selectedFile[index].name
      if (filename) {
        const filenameUpdated = filename.substring(0, filename.lastIndexOf('.'))
        try {
          const uploadFile: any = await s3.uploadFile(selectedFile[index], filenameUpdated)
          urls.push(uploadFile.location)
        } catch (error) {
          return error
        }
      }
    }

    return urls
  }

  const gotoBoardHandler = () => {
    formik.handleSubmit()
    navigate(`/board-view/board/${cardDetails.boardId}`)
    setTimeout(() => {
      handleClose()
    }, 3000);
  }
  const getAddedAndRemovedTags = () => {
    const addedTags = selectedTags?.filter((tag: any) => !transformedTags?.some((t: any) => t.value === tag.value));
    const removedTags = transformedTags?.filter((tag: any) => !selectedTags?.some((t: any) => t.value === tag.value));
    setTagsToUpdate({ addedTags, removedTags });
  };
  useEffect(() => {
    getAddedAndRemovedTags()
  }, [selectedTags])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: cardSchema,
    onSubmit: async (values: any) => {
      setDisableBtn(true)
      const { utcDate, utcTime }: any = getTimeDateFormate(startDate)
      const fileLinks = await handleSubmission()
      const postDataBeforeRule = {
        created_by: fullName,
        last_modified_by: fullName,
        board_name: allBoards.find((item: any) => item.board.id === boardId)?.board.name.trim(),
        card_name: values.cardName,
        description: cardDescription,
        due_date: startDate ? utcDate : null,
        due_time: startDate ? utcTime : null,
        number: `${cardLength || cardDetails.number}`,
        position: cardDetails.position,
        priority: values.priority === 'Select' ? null : values.priority || cardDetails.priority,
        rag: null,
        status: values.status,
        version: 1,
        applicationId: null,
        boardId: boardId,
        board_column_id: columnId,
        categoryId: null,
        componentId: null,
        customerId: null,
        productId: null,
        typeId: null,
        reporter: fullName,
        new_tagIds : tagsToUpdate?.addedTags?.length > 0 
          ? tagsToUpdate.addedTags.map((tag: any) => tag.value) 
          : [],      
        remove_tagIds : tagsToUpdate?.removedTags?.length > 0 
          ? tagsToUpdate.removedTags.map((tag: any) => tag.value) 
          : [],
        assignees:
          optionsOfAssignee.length > 0
            ? selectAutoAssignee.map((item: any) => {
              return {
                board_person_id: item.value,
              }
            })
            : [],
        file_attachments: fileLinks,
        updateReminders: convertIdIntoCardReminderId(reminders as any),
        removeReminders: deleteReminderIds,
      }
      const currentCol=columns?.find((col:any)=> col.id === columnId)
      const postData = applyColumnRules(postDataBeforeRule, currentCol);

      try {
        const updateData = await updateCardById(postData, cardDetails.id, id, boardId)
        const updatedCard = { ...updateData.data.data.card }
        setSelectedItem(updatedCard)
        const addedTags = tagsToUpdate.addedTags.map((tag: any) => ({ label: tag.label, value: tag.value }));
        addedTags.length && dispatch(addBoardTags(addedTags));
    
        const removedTagIds = tagsToUpdate.removedTags.map((tag: any) => tag.value);
        removedTagIds.length && dispatch(removeBoardTags(removedTagIds));
        const searchedCardData = allSearchData['Cards'.toLowerCase()]['Cards'.toLowerCase()]
        if (searchedCardData?.length > 0) {
          const data = [...searchedCardData]
          const index =
            data?.length > 0 &&
            (data?.findIndex((data: any) => data?.id === cardDetails?.id) as number)
          if (index !== -1) {
            data[index as number] = updatedCard
            dispatch(setUpdatedItem([...data]))

            dispatch(
              updateSearchData({
                tab: 'cards',
                data: [...data],
              })
            )
          }
        }

        const webSocketPayload: any = {
          event: 'publish',
          channel: boardId,
          userId: id,
          command: 'UPDATE',
          operation: webSocketOperations.cardUpdate,
          payload: {
            boardId: postData.boardId,
            cardId: cardDetails.id,
            name: postData.card_name,
            priority: postData.priority,
            boardColumnId: postData.board_column_id,
            alert: `${capitalizeEachWord(fullName)} did changes in ${postData.board_name}- ${postData.number
              }.`,
          },
        }
        if (boardId !== cardDetails.boardId) {
          sendJsonMessage(webSocketPayload)
          webSocketPayload.channel = cardDetails.boardId
          sendJsonMessage(webSocketPayload)
        } else {
          sendJsonMessage(webSocketPayload)
        }
        childCommentRef.current.addComment()
        childCommentRef.current.updateComment()
        dispatch(setIsRender({ isRender: !isRender }))
        dispatch(setIsCardUpdated({ isCardUpdated: !isCardUpdated }))
      } catch (error) {
        return error
      } finally {
        setIsCardSaved(true)
        setDisableBtn(false)
        setSelectedFile([])
        setSaveBtnProp({ color: 'success', title: 'Saved' })

        setTimeout(() => {
          setIsCardSaved(false)
        }, 3000)

        if (location.pathname !== '/home/board' && location.pathname !== '/home') {
          dispatch(fetchAllCardsofLoggedInUser())
          dispatch(fetchAllCardByBoardId({ boardId: boardId, userId: id }))
        }
      }
      // setBoardId('')
    },
  })

  function convertIdIntoCardReminderId(reminders: any) {
    const updatedReminders: any = reminders.map(({ id, ...reminder }: any) => {
      return { ...reminder, cardReminderId: id }
    })

    const removeNulls = removeNullFromReminder(updatedReminders)
    return removeNulls
  }

  useEffect(() => {
    setBoardId(cardDetails?.boardId)
    fetchDescription()
    setSelectedTags(transformedTags)
    return () => setSaveBtnProp({ title: 'Save', color: 'primary' })
  }, [cardDetails.id])

  useEffect(() => {
    const urlLinks: any = []
    if (cardDetails.file_attachments) {
      for (const file of cardDetails.file_attachments) {
        for (const [key, value] of Object.entries(file)) {
          const getFileName = (value as string)
            .toLowerCase()
            ?.split(`${process.env.REACT_APP_ENVIRONMENT}/`)[1]
          urlLinks.push({ key, value: value, showName: getFileName })
        }
      }
      setSelectedFile(urlLinks)
    }
    return () => {
      setSelectedFile([])
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (boardId) {
      getBoardPersonHandler(boardId)
      fetchColumnHandler(boardId)
    }

    //eslint-disable-next-line
  }, [boardId])

  useEffect(() => {
    if (cardDetails?.card_reminders?.length > 0) {
      const filteredReminders = cardDetails.card_reminders?.map((reminder: any) => {
        //eslint-disable-next-line
        const { cardId, createdAt, updatedAt, ...rest } = reminder
        return Object.entries(rest).reduce((acc: any, [key, value]: [string, any]) => {
          if (value !== null) {
            acc[key] = value
          }
          return acc
        }, {})
      })

      if (filteredReminders.length > 0) {
        setReminders([...filteredReminders])
      }
    }

    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    // setEditorDescriptionState(cardDetails?.content || cardDescription)
    setCheckIsDescriptionExist(cardDescription ? true : false)
  }, [cardDescription])

  return (
    <>
      <div className='row d-flex justify-content-between border-bottom fs-2 fw-bold p-8 mb-5'>
        <div className='text-primary col-6'>
          {longWordSlicing(cardDetails.key.trim())}
          {isBoard === false && (
            <Button
              className='btn-custom btn-custom-secondary mx-3 btn btn-primary py-2 px-3'
              onClick={gotoBoardHandler}
            >
              Go To Tasks
            </Button>
          )}
        </div>
        <div className='col-4 text-end d-flex justify-content-center align-items-center gap-2'>
          <Button
            disabled={(boardId?.length > 0 && columnId) || disableBtn ? false : true}
            className={`${disableBtn && 'btn-custom-secondary'} w-75 btn-sm btn-${saveBtnProp.color
              } `}
            onClick={() => {
              formik.handleSubmit()
            }}
          >
            {
              disableBtn ? (
                <Spinner animation='border' variant='light' />
              ) : (
                <span>{saveBtnProp.title}</span>
              )
              // isCardSaved ? (
              //   'Saved'
              // ) : (
              //   'Save'
              // )
            }
          </Button>
          {isCardSaved === true && (
            <i
              style={{
                fontSize: 20,
                color: '#51cd89',
              }}
              className='bi bi-check-lg'
            ></i>
          )}
        </div>
      </div>
      <div>
        <div className='container'>
          {/* {isColumnLoading || isBoardLoading ? (
            <EditCardSkeleton />
          ) : ( */}
            <UpdateCardForm
              formik={formik}
              setCardLength={setCardLength}
              boardId={boardId}
              allBoardsVar={allBoards}
              setBoardId={setBoardId}
              cardDetails={cardDetails}
              columns={columns}
              columnId={columnId}
              setColumnId={setColumnId}
              // handleChange={EditorImageViewerHandler}
              deleteFileHandler={deleteFileHandler}
              selectedFile={selectedFile}
              changeHandler={selectFileHandler}
              fileErrorMsg={fileErrorMsg}
              setFileErrorMsg={setFileErrorMsg}
              sendAlertMsg={sendAlertMsg}
              setSendAlertMsg={setSendAlertMsg}
              setSendAlertTo={setSendAlertTo}
              sendAlertTo={sendAlertTo}
              childCommentRef={childCommentRef}
              optionsForSendAlert={optionsForSendAlert}
              setSendAlertToComment={setSendAlertToComment}
              sendAlertToComment={sendAlertToComment}
              sendAlertToBoardPerson={sendAlertToBoardPerson}
              boardPersonsForSendAlert={boardPersonsForSendAlert}
              setEditorComment={setEditorComment}
              editorComment={editorComment}
              sendAlertMsgComment={sendAlertMsgComment}
              setStartDate={setStartDate}
              startDate={startDate}
              options={optionsOfAssignee}
              setSelected={setSelectAutoAssignee}
              selected={selectAutoAssignee}
              disableBtn={disableBtn}
              setEditor={setCardDescription}
              editor={cardDescription}
              checkIsDescriptionExist={checkIsDescriptionExist}
              setReminders={setReminders}
              reminders={reminders}
              isHomeSearching={isHomeSearching}
              setDeletedReminderIds={setDeletedReminderIds}
              isColumnLoading={isColumnLoading}
              isBoardLoading={isBoardLoading}
              isUserAssignLoading={isUserAssignLoading}
              isCardDescriptionLoading={isCardDescriptionLoading}
              tags={tags}
              loading={tagsLoading}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              transformedTags={transformedTags}
                commentsLoading={commentsLoading}
                setCommentsLoading={setCommentsLoading}
            />
          {/* )} */}
        </div>
      </div>

      {/* {imageSrc && <ImageModal imageSrc={imageSrc} setShow={setShow} show={show} />
            }
            {video.length > 0 && (
                <VideoShowModal videoSrc={video} setShowVideo={setShowVideo} showVideo={showVideo} />
            )} */}
    </>
  )
}

export default EditCardForSearching
