export const cleanMentions = (content: string | null):string => {
    if (!content) return ''; // Return an empty string if content is null or undefined
  
    // Parse the content string into a DOM element
    const parser = new DOMParser();
    const parsedContent = parser.parseFromString(content, 'text/html').body; // Get the body of the parsed HTML
  
    // Now you can safely call querySelectorAll on the parsed content
    const mentionElements = parsedContent.querySelectorAll('span.mention') as NodeListOf<HTMLSpanElement>;
  
    mentionElements.forEach((mention: any) => {
      if (mention.hasAttribute('data-id')) {
        mention.removeAttribute('data-description'); // Remove the attribute
        // mention.removeAttribute('data-id'); // Remove the attribute
      }
    });
  
    // Convert the modified content back to a string if needed
    return parsedContent.innerHTML;
  };